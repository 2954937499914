import React, { useState, useEffect } from "react";
import api from "services/api";

export default function About(props) {
  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "about_us",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect( () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  return (
    <>
      <div class="container topBottonPadding50">
        <br />
        <section className="CMS_content white">
          <div className="page_title">
            {pageContent && pageContent.content_data.page_title}
          </div>
          <div className="common_paragraph">
            {pageContent && renderHTML(pageContent.content_data.content)}
          </div>
        </section>
        <br />
      </div>
      <div class="container topBottonPadding50 d-none">
        <section className="CMS_content white">
          <div className="page_title">The World's Favorite Social Casino</div>
          <div className="common_paragraph">
            <div className="common_paragraph about_page_text">
              Sweeplay Casino is developed in-house by our team of talented
              games designers and developers, and also developed/licensed off
              3rd party service providers. This provides IGW with both
              operational flexibility and commercial advantages. IGW continue to
              invest into the development of proprietary games and content to
              further differentiate itself from the competitors.
            </div>
            <div className="common_paragraph about_page_text">
              Our current portfolio includes 80+ exciting games for desktop and
              mobile players. We continue to grow our offerings to players with
              new games being launched on a monthly basis.
            </div>
            <div className="page_title">The IGW Team</div>
            <div className="common_paragraph about_page_text">
              IGW is a diverse company which believes in a work hard / play hard
              ethos. We have over 400 staff located globally working for us.
            </div>
            <div className="page_title">Frequently Asked Questions</div>
            <div className="common_headline">Is Sweeplay Casino Legal?</div>
            <div className="common_paragraph about_page_text">
              Sweeplay Casino is a leading social casino site. In addition to
              our Fun Play Coin games, we use a unique sweepstakes model which
              allows the safe and secure redemption of Sweepstake Coins for cash
              prizes to players in the US and Canada. We pride ourselves on
              adhering to the laws in the jurisdictions that we operate in. We
              engage with leading experts in those jurisdictions to ensure that
              we are fully compliant with all applicable laws.
            </div>
            <div className="common_headline">Who is Sweeplay Casino?</div>
            <div className="common_paragraph about_page_text">
              Sweeplay Casino is a social casino owned and operated by IGW Malta
              Limited, part of the IGW Group.
            </div>
            <div className="common_paragraph about_page_text">
              Sweeplay Casino aims to be a leading social casino bringing fun
              and excitement to social casino fans globally. Our initial focus
              is on the US, "the home of casinos", and Canada, but we intend to
              expand globally very soon, making Sweeplay Casino truly global!
            </div>
            <div className="common_headline">
              Is Sweeplay Casino the only social gaming company to use
              Sweepstakes Prizes?
            </div>
            <div className="common_paragraph about_page_text">
              No, gaming companies offering sweepstakes with prizes is nothing
              new. There have been, and still are, many companies offering
              sweepstakes with prizes.
            </div>
            <div className="common_headline">What are "Sweepstakes"?</div>
            <div className="common_paragraph about_page_text">
              Sweepstakes are trade promotions with prizes. They are used to
              promote the sales of products – in our case – our Fun Play Coins –
              through prize giveaways and have been used for decades, worldwide.
            </div>
            <div className="common_headline">
              How does Sweeplay Casino's Sweepstakes Model Work?
            </div>
            <div className="common_paragraph about_page_text">
              Sweeplay Casino, sells Fun Play Coins, a virtual currency which
              you can accumulate and play with, but which cannot be traded,
              transferred or redeemed for real money.
            </div>
            <div className="common_paragraph about_page_text">
              No purchase is necessary to enter Sweeplay Casino's sweepstakes.{" "}
            </div>
            <div className="common_paragraph about_page_text">
              Entries into Sweeplay Casino's sweepstakes are given to players in
              the form of Sweepstake Coins. Sweepstake Coins cannot be purchased
              and it has no inherent value. Players can always obtain Sweepstake
              Coins free of charge as a bonus with the purchase of Fun Play
              Coins, or via an Alternative Methods of Entry (AMOE) such as
              mailing in.
            </div>
            <div className="common_paragraph about_page_text">
              Sweepstake Coins can be used to play Sweeplay Casino's games.
              Sweeps Coins won as prizes (from playing our Sweepstake Coins
              games) can be redeemed for real cash prizes.
            </div>
            <div className="common_paragraph about_page_text">
              Do I Need to satisfy an Identity Verification Process (KYC – Know
              Your Customer) and will my account be restricted until my identity
              has been verified?
            </div>
            <div className="common_headline">
              Collection of information relating to identity{" "}
            </div>
            <div className="common_paragraph about_page_text">
              Sweeplay Casino needs to be able to positively identify our
              customers. The first step in this process is collecting certain
              personal identity information (such as your name, date of birth
              and residential address) from you before you make a purchase of
              Gold Coins.
            </div>
            <div className="common_headline">Verification of identity</div>
            <div className="common_paragraph about_page_text">
              After collecting the information, Sweeplay Casino may separately
              be required to verify that the information collected is correct in
              order to comply with legal obligations that are applicable to us
              and in line with our commitment to responsible gameplay. You will
              be notified in your online account once we are required to verify
              your identity. If you fail to submit the required documentation
              within the time period indicated to you in such notification, you
              will not be able to play our games or redeem any Sweepstake Coins
              until your identity has been verified.
            </div>
            <div className="common_headline">
              Verification of account ownership
            </div>
            <div className="common_paragraph about_page_text">
              Prior to being able to redeem any Sweepstake Coins for cash
              prizes, Sweeplay Casino needs to ensure that the account into
              which any cash prize will be paid is in your name. We are unable
              to pay any prizes into accounts that are not in your name.
            </div>
            <div className="common_headline">
              What Documents Do I Need to Provide as Part of the Verification
              Process?
            </div>
            <div className="common_headline">Verification of identity</div>
            <div className="common_paragraph about_page_text">
              Verification of your identity (including your name, date of birth
              and residential address) may be achieved by submitting one of the
              following documents which will normally need to be
              government-issued:
            </div>
            <ul>
              <li className="common_paragraph about_page_text">
                driver's licence;
              </li>
              <li className="common_paragraph about_page_text">passport; or</li>
              <li className="common_paragraph about_page_text">
                identity card.
              </li>
            </ul>
            <div className="common_paragraph about_page_text">
              The document submitted must still be current. In order to
              cross-check that the identity document submitted is valid, you may
              be requested to upload a photo or real-time selfie image of
              yourself via our dedicated portal.
            </div>
            <div className="common_paragraph about_page_text">
              If your identity document does not display your residential
              address, you may also need to submit one of the following
              documents as proof of your residential address:
            </div>
            <ul>
              <li className="common_paragraph about_page_text">
                bank statement;
              </li>
              <li className="common_paragraph about_page_text">
                utility bill; or
              </li>
              <li className="common_paragraph about_page_text">
                letter from a government authority.
              </li>
            </ul>
            <div className="common_paragraph about_page_text">
              This document must show your name, residential address and a date
              of issue that is within the last 6 months.
            </div>
            <div className="common_headline">
              Verification of account ownership
            </div>
            <div className="common_paragraph about_page_text">
              In order to demonstrate that you own the bank account into which
              you would like any redemption of Sweepstake Coins for cash prizes
              paid, we will require a copy of a bank statement (dated within the
              last 6 months) relating to that account in your name.
            </div>
            <div className="common_paragraph about_page_text">
              If you have a joint account (that is, an account in the name of
              two or more people), all account holders will need to satisfy the
              identity verification process outlined above, meaning all account
              holders will need to have their name, date of birth and
              residential address verified.
            </div>
            <div className="common_headline">
              How Long Does it Take to Verify My Documents?
            </div>
            <div className="common_paragraph about_page_text">
              Once we have received the required documents in their correct
              form, we will verify them as soon as possible. The process depends
              on whether you have sent the correct form of identification or
              account ownership to us. If everything is correct, it normally
              takes about 5 days to complete the account verification process.
              Once your account has been verified, it normally takes about 10
              days for your cash prize to be processed and reach your account.
            </div>
            <div className="common_headline">Additional Verification</div>
            <div className="common_paragraph about_page_text">
              As per the{" "}
              <a
                target="_self"
                rel=""
                class="css-1xs6gn3"
                href="/terms-and-conditions"
              >
                Sweeplay Casino Terms and Conditions
              </a>
              , we reserve the right to request any additional documents and/or
              information that we deem necessary in order to comply with our
              legal obligations, including to verify your identity, the
              ownership of your account, your source of wealth or your source of
              funds.
            </div>
            <div className="common_headline">
              What's the Difference Between Fun Play Coins and Sweepstake Coins?
            </div>
            <div className="common_paragraph about_page_text">
              Fun Play Coins can be purchased by players to use with games in
              Gold Coin mode. Fun Play Coins can be used to play Sweeplay Casino
              games, but they cannot be transferred, traded or redeemed for cash
              prizes.
            </div>
            <div className="common_paragraph about_page_text">
              Players can always obtain Sweepstake Coins free of charge as a
              bonus with the purchase of Fun Play Coins, or via an Alternative
              Methods of Entry (AMOE) such as mailing in. Sweepstake Coins can
              be used to play Sweeplay Casino games in Sweepstake Coins mode.
              Sweepstake Coins won as prizes (from playing our Sweepstake Coins
              games) can be redeemed as cash prizes.
            </div>
            <div className="common_headline">
              What Other Companies Use Sweepstakes?
            </div>
            <div className="common_paragraph about_page_text">
              Many companies worldwide use sweepstakes to promote and sell their
              products. Sweepstakes promotions have been running for decades in
              the US and other countries around the world.
            </div>
            <div className="common_paragraph about_page_text">
              Companies such as McDonalds, Publishers Clearing House, Reader's
              Digest, Pepsi and Proctor &amp; Gamble all hold widely known, and
              often global, sweepstakes promotions.
            </div>
            <div className="common_headline">
              What is the Risk Laws Will Just Change?
            </div>
            <div className="common_paragraph about_page_text">
              Low. Sweepstakes are regulated in the US is on a state-by-state
              basis, so laws in 50 states would need to change.
            </div>
            <div className="common_paragraph about_page_text">
              Further, since Sweepstakes trade promotions are offered by a large
              number of brands and companies, including many multinationals, any
              regulatory change would have broad consequences and likely be
              challenged.
            </div>
            <div className="common_headline">
              So Why Can't Other Casino Companies Implement the Same Sweepstakes
              Model?
            </div>
            <ol>
              <li className="common_paragraph about_page_text">
                Regulatory &amp; Partner risks: Our competitors are generally
                reluctant to explore innovative models that are not considered
                "mainstream". This extends to key marketing, distribution and
                payment processing partners, who competitors would need to
                satisfy as to the legitimacy of their business model, a process
                which has taken Sweeplay Casino several years.
              </li>
              <li className="common_paragraph about_page_text">
                Technology &amp; Operations: The technology required to operate
                a sweepstakes prize platform is very different to that of a
                virtual currency only game, requiring far more robust, secure
                and thorough gameplay and reporting functionality. Operations
                supporting sweepstakes are also very different to games
                companies, as the payments, fraud and customer service profiles
                are very different.
              </li>
            </ol>
            <div className="common_headline">
              Can Sweeplay Casino's Sweepstakes Model Apply to Other Gaming
              Products?
            </div>
            <div className="common_paragraph about_page_text">
              Yes. Some of these are offered at our sister site{" "}
              <a
                target="_blank"
                rel="noopener noreferrer"
                class="css-1xs6gn3"
                href="https://www.globalpoker.com"
              >
                www.globalpoker.com
              </a>
              .
            </div>
            <div className="common_headline">
              How Do I Deposit Money Into My Sweeplay Casino Account?
            </div>
            <div className="common_paragraph about_page_text">
              You{" "}
              <strong>
                cannot deposit money into your Sweeplay Casino account
              </strong>
              . Sweeplay Casino is not a 'traditional online casino site' where
              you can deposit money to play with. Sweeplay Casino is a social
              casino site which offers game play for our two virtual currencies,
              Gold Coins and Sweepstake Coins
            </div>
            <div className="common_paragraph about_page_text">
              If you want to enjoy the fun of the Fun Play Coin play all you
              need to do is register and you are ready to start playing. If you
              want to take part in our Sweepstake Coins games, you can do so
              using Sweepstake Coins . Sweepstake Coins be obtained free of
              charge in a number of ways including, as a promotion when you
              purchase Fun Play Coins, by winning one of our Sweepstake Coins
              giveaway competitions or by sending us a letter. Check out our
              Sweeps Rules page for further information on how to obtain your
              free Sweepstake Coins
            </div>
            <div className="common_headline">
              What are the Minimum and Maximum Amount of Sweepstake Coins I Can
              Convert to Cash?
            </div>
            <div className="common_paragraph about_page_text">
              You need to have won a minimum of 100 Sweepstake Coins as prizes
              before you can redeem them for cash. There is currently no maximum
              amount.
            </div>
            <div className="common_headline">Winnings</div>
            <div className="common_paragraph about_page_text">
              We are able to provide you with a record of your Sweepstake Coins
              prizes. However,
              <strong>
                {" "}
                you are responsible for the payment of any taxes with respect to
                those winnings
              </strong>
              . Please refer to paragraph 7 of the Sweeps Rules with respect to
              your responsibilities for the payment of taxes.
            </div>
            <div className="common_headline">
              How Random are the Spins Used for Sweeplay Casino Slots Games?
            </div>
            <div className="common_paragraph about_page_text">
              The Random Number Generator (RNG) used by Sweeplay Casino utilises
              a Mersenne Twister with background cycling. This was certified by
              iTechLabs and Gaming Associates who confirmed that it uses a well
              known algorithm to generate random numbers. The numbers generated
              by this RNG have passed Marsaglia's "diehard" tests for
              statistical randomness. iTech Labs and Gaming Associates have
              found that number sequences are unpredictable, non-repeatable and
              uniformly distributed.
            </div>
          </div>
        </section>
      </div>
      {/* <section class="privacy white">
        <div class="container">
          <div className="page_title about_page"></div>
        </div>
      </section> */}
    </>
  );
}
