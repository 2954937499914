// var configEnv = "development";
// var configEnv = "staging";
var configEnv = "production";

var config = {
  //##########Live config##########
  production: {
    appUrl: "https://sweeplay.com",
    apiUrl: "https://api.sweeplay.com/api/v1",
    staticUrl: "https://controlroom83.sweeplay.com/public",
    liveChatUrl:
      "https://controlroom83.sweeplay.com/public/livechat/php/app.php?widget-inline",
    // FBAppId: "523317649620043",
    // GoogleClientId: "477163522509-gj1eq4tt5oj0vq2hut66spsvhgd51oq2.apps.googleusercontent.com",
    FBAppId: "1172568450039492",
    GoogleClientId:
      "206987246875-k2roobn2stchbhvjhvs3t7e33dabfn9b.apps.googleusercontent.com",
    debug: false,
  },

  //#########Stage config##########
  staging: {
    appUrl: "https://sweeplay.cbstagings.com",
    apiUrl: "https://sweeplayapi.cbstagings.com/api/v1",
    staticUrl: "https://sweeplayapi.cbstagings.com/api/v1",
    publicDir: "https://sweeplayboss.cbstagings.com/public",
    liveChatUrl:
      "https://sweeplayboss.cbstagings.com/public/livechat/php/app.php?widget-inline",
    FBAppId: "1172568450039492",
    GoogleClientId:
      "918922690152-tk90kb6fiq5ssekf7fcnn2d3hfrkvkkv.apps.googleusercontent.com",
    debug: false,
  },

  //##########Local config##########
  development: {
    appUrl: "http://localhost:3000",
    apiUrl: "http://casinoapi.local.host/api/v1",
    staticUrl: "http://casinoapi.local.host/api/v1",
    liveChatUrl:
      "http://casinoadmin.local.host/public/livechat/php/app.php?widget-inline",
    fileSourceUrl: "http://casinoadmin.local.host/public",
    debug: true,
  },
};

var finalConfig = config[configEnv];

finalConfig.defaultLanguage = {
  code: "en",
  language: "English",
  flag_path: finalConfig.fileSourceUrl + "/images/flags/svg/gb.svg",
};

export default finalConfig;

// export default config[configEnv];
