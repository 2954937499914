import React, { useState, useEffect, useRef } from "react";
import { ProgressBar } from "react-bootstrap";
import LoadMore from "elements/LoadMore";
import GameItem from "elements/GameElements/GameItem";
// import SearchBox from "elements/SearchBox";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { Modal } from "react-bootstrap";
import api from "services/api";
import { __ } from "services/Translator";
import CategoryGames from "elements/GameElements/CategoryGames";

// import JackpotTicker from "elements/JackpotTicker";

export default function CategoryGameContainer(props) {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const gameTypeAPISlugs = {
    all: "all",
    slots: "video_slots",
    blackjack: "blackjack",
    "video-poker": "table_games",
    "live-dealer": "live_casino",
    "live-casino": "live_casino",
    "bonus-buy": "bonus_buys",
    megaways: "megaways",
    jackpot: "jackpot",
    "table-games": "table_games",
    popular: "popular",
    new: "new",
    search: "search_results",
    "provably-fair": "provably-fair",
  };

  const gameTitles = {
    slots: __("Slots"),
    blackjack: __("Blackjack"),
    "video-poker": __("Video Poker"),
    "live-dealer": __("Live Dealer"),
    "live-casino": __("Live Casino"),
    "bonus-buy": __("Bonus Buys"),
    megaways: __("Megaways"),
    jackpot: __("Jackpot Games"),
    "table-games": __("Table Games"),
    popular: __("Popular"),
    new: __("New"),
    search_results: __("Search Results"),
    "provably-fair": __("Provably Fair"),
  };
  const gameType = params.category ? params.category : "popular";

  const [pagination, setPagination] = useState(null);
  const [games, setGames] = useState([]);
  const [gameSorting, setGameSorting] = useState(null);
  // const [perPage, setPerPage] = useState(30);
  const [gameLoaded, setGameLoaded] = useState(0);
  const [totalGame, setTotalGame] = useState(0);

  useEffect(() => {
    // if (page == 1) {
    //   setPerPage(18);
    // } else {
    //   setPerPage(30);
    // }
  }, []);

  const getGames = async (page) => {
    var perPage = 30;
    if (page == 1) {
      if (props.isMobileDevice) {
        perPage = 9;
      } else if (window.innerWidth > 767 && window.innerWidth < 992) {
        perPage = 12;
      } else if (window.innerWidth < 768) {
        perPage = 9;
      } else {
        perPage = 18;
      }
    } else {
      perPage = 30;
    }
    // alert(perPage);
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      game_types: [gameTypeAPISlugs[gameType]],
      page: page,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setIsSearchResultsShowing(false);
      props.setSearchStr("");
      if (page === 1) {
        setGames(response.data.games);
      } else {
        var realGameType = gameTypeAPISlugs[gameType];

        var gamesClone = JSON.parse(JSON.stringify(games));

        gamesClone[realGameType].gamelist = [
          ...gamesClone[realGameType].gamelist,
          ...response.data.games[realGameType].gamelist,
        ];
        setGames(gamesClone);
      }

      setGameLoaded(
        page === 1
          ? response.data.loadedGameCount
          : gameLoaded + response.data.loadedGameCount
      );
      setTotalGame(response.data.gameCount);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const onPageChange = (page) => {
    if (gameType === "search") {
      searchGames(page, null);
    } else {
      getGames(page);
    }
  };

  useEffect(() => {
    if (gameType === "search") {
      if (props.searchStr.length) {
        searchGames(1);
      } else {
        history.push("/games/all");
      }
    } else {
      getGames(1);
    }
  }, [gameSorting, gameType, props.searchStr, props.selectedProviders]);

  const searchGames = async (page) => {
    var perPage = 30;
    var serchParams = {
      search_query: props.searchStr,
      page: page,
      per_page: perPage,
      providers: props.selectedProviders,
    };

    var response = await api.post("/games/search", serchParams);
    if (response.status === 200) {
      setIsSearchResultsShowing(true);
      setGames(response.data.games);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }

      // setGameLoaded(page * perPage);

      setGameLoaded(
        page === 1
          ? response.data.loadedGameCount
          : gameLoaded + response.data.loadedGameCount
      );

      setTotalGame(response.data.gameCount);
    }
  };

  const [isSearchResultsShowing, setIsSearchResultsShowing] = useState(false);
  return (
    <>
      <CategoryGames
        gameList={games[gameTypeAPISlugs[gameType] ?? "all"]?.gamelist ?? []}
        categoryName={gameTitles[gameType] ?? ""}
        isSearchResults={isSearchResultsShowing}
        category={gameType}
        onPageChange={onPageChange}
        pagination={pagination}
        setGameSorting={setGameSorting}
        gameSorting={gameSorting}
        showPagination={true}
        gameLoaded={gameLoaded}
        totalGame={totalGame}
        providers={props.providers}
        selectedProviders={props.selectedProviders}
        setSelectedProviders={props.setSelectedProviders}
        isAuthenticated={props.isAuthenticated}
        setSearchStr={props.setSearchStr}
        {...props}
      />
    </>
  );
}
