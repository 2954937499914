import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import api from "../../services/api";
import AccountActivityMobileDataView from "./parts/AccountActivityMobileDataView";
import AccountActivityDataView from "./parts/AccountActivityDataView";
import { isMobile } from "../../services/isMobile";
import Pagination from "../../elements/Pagination";
import Translator, { __ } from "services/Translator";

export default function AccountActivity(props) {
  // const __ = (text) => text;

  const defaultForm = {
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };

  const getResults = async () => {
    // props.setSpinner(true);
    var response = await api.post("/history/account-activity", form);
    // props.setSpinner(false);
    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    if (form.triggerSearch) {
      getResults();
    }
  }, [form.triggerSearch]);

  const doReset = () => {
    setForm(defaultForm);
  };

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };

  //Mobile Device detection
  const [isMobileDevice, setIsMobileDevice] = useState(false);
  useEffect(() => {
    setIsMobileDevice(isMobile());
  }, []);

  return (
    <>
      <div className="profile">
        <div className="container">
          <div className="row">
            <div className={isMobileDevice ? "col-md-12" : "col-md-12"}>
              <div className="profile_right_content">
                <div className="profile_headline">
                  Account Activity
                  <span className="toggle_content">
                    <div class="transaction_mode d-none">
                      <Link
                        hreflang={Translator.getSelectedLanguage().code}
                        to="#"
                        // onClick={() => toggleTab("login")}
                        className="mode_btn"
                      >
                        Deposits
                      </Link>
                      <Link
                        hreflang={Translator.getSelectedLanguage().code}
                        to="#"
                        // onClick={() => toggleTab("Sign up")}
                        className="mode_btn active"
                      >
                        Withdrawals
                      </Link>
                    </div>
                  </span>
                </div>
                <br />
                <div className="clearfix"></div>

                {!isMobileDevice && (
                  <AccountActivityDataView dataSet={results} />
                )}

                {isMobileDevice && (
                  <AccountActivityMobileDataView dataSet={results} />
                )}

                <Pagination data={pagination} onPageChange={onPageChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
