import React, { useState, useEffect } from "react";
import auth from "services/auth";
import { Redirect, Route, Link, useLocation } from "react-router-dom";
import Translator, { __ } from "services/Translator";

const StickyFooterMenu = (props) => {
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [showWalletModal, setShowWalletModal] = useState(false);
  useEffect(() => {
    // alert(showWalletModal)
    if (showWalletModal == true) {
      props.showWalletModal(showWalletModal);
    }
  }, [showWalletModal]);

  const handleChatToggle = () => {
    if (typeof window.Tawk_API !== "undefined") {
      window.Tawk_API.toggle();
    } else {
      console.error("Tawk_API is not available.");
    }
  };
  //Search functionality
  const [showSearch, setShowSearch] = useState(false);
  const [searchVal, setSearchVal] = useState("");
  const updateSearchInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    setSearchVal(value);
  };

  // useEffect(() => {
  //   props.searchVal(searchVal);
  // }, [searchVal]);

  //GamePlay Page detection
  const location = useLocation();

  const [showSlotsLink, setShowSlotsLink] = useState(false);

  useEffect(() => {
    const playPath = location.pathname.split("/");
    setShowSlotsLink(playPath.includes("live-casino"));
  }, [location]);

  const setSupportSidebarOpen = props.setSupportSidebarOpen;
  const supportSidebarOpen = props.supportSidebarOpen;
  const toggleSupportSidebar = () => {
    setSupportSidebarOpen(!supportSidebarOpen);
    props.setShowRightSidebar(false);
  };

  return (
    <>
      <section className="stickyMenu">
        <div className="nav-bar">
          <Link
            hreflang={Translator.getSelectedLanguage().code}
            onClick={() => {
              props.setShowLeftSidebar(!props.showLeftSidebar);
            }}
            className={"nav-item "}
          >
            <div className="icon">
              <img
                alt=""
                src={
                  require("../assets/sweeplay/images/stickyFooter/menu.svg")
                    .default
                }
              />
            </div>
            <span>{__("Menu")}</span>
          </Link>
          <Link
            hreflang={Translator.getSelectedLanguage().code}
            to="/games/all"
            className={"nav-item " + (location.pathname == "/" ? "" : "")}
          >
            <div className="icon">
              <img
                alt=""
                src={
                  require("../assets/sweeplay/images/stickyFooter/games.svg")
                    .default
                }
              />
            </div>
            <span>{__("Games")}</span>
          </Link>
          {!props.isAuthenticated ? (
            <Link
              hreflang={Translator.getSelectedLanguage().code}
              onClick={() => {
                props.setModalSignUpPropsData(true);
                setTimeout(function () {
                  props.setModalSignUpPropsData(false);
                }, 500);
              }}
              className={"nav-item middleBtn "}
            >
              <div className="middleItem">
                <div className="icon">
                  <img
                    alt=""
                    src={
                      require("../assets/sweeplay/images/stickyFooter/play.svg")
                        .default
                    }
                  />
                </div>
                <span>{__("Play")}</span>
              </div>
            </Link>
          ) : (
            <Link
              hreflang={Translator.getSelectedLanguage().code}
              onClick={() => {
                props.setShowBuyModalG(true);
              }}
              className={"nav-item middleBtn"}
            >
              <div className="middleItem">
                <div className="icon">
                  <img
                    alt=""
                    style={{ width: "25px" }}
                    src={
                      require("../assets/sweeplay/images/stickyFooter/buy.svg")
                        .default
                    }
                  />
                </div>
                <span>{__("Buy")}</span>
              </div>
            </Link>
          )}
          <Link
            hreflang={Translator.getSelectedLanguage().code}
            to="/promotions"
            className={"nav-item "}
          >
            <div className="icon">
              <img
                alt=""
                src={
                  require("../assets/sweeplay/images/stickyFooter/coupon.svg")
                    .default
                }
              />
            </div>
            <span>{__("Promo")}</span>
          </Link>
          <Link
            hreflang={Translator.getSelectedLanguage().code}
            onClick={toggleSupportSidebar}
            className={"nav-item "}
          >
            <div className="icon">
              <img
                alt=""
                src={
                  require("../assets/sweeplay/images/stickyFooter/liveChat.svg")
                    .default
                }
              />
            </div>
            <span>{__("Livechat")}</span>
          </Link>
        </div>
      </section>
    </>
  );
};

export default StickyFooterMenu;
