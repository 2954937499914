import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
const AccountActivityMobileDataView = (props) => {
  const [results, setResults] = useState({});
  useEffect(() => {
    setResults(props.dataSet);
  }, [props]);

  return (
    <div className="transactions mobile_tr_view">
      {results.length > 0 && (
        <Accordion>
          {results.map((item, index) => (
            <Accordion.Item eventKey={index}>
              <Accordion.Header>
                <div className="mobile_tr_heading">
                  <div className="single_data">
                    <label>Date</label>
                    <div className="tr_data">{item.date_created}</div>
                  </div>
                  <div className="single_data">
                    <label>Action</label>
                    <div className="tr_data_text">{item.account_action}</div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="tr_data">
                  <div className="single_data">
                    <label>Browser</label>
                    <div className="tr_data_text">{item.browser}</div>
                  </div>
                  <div className="single_data">
                    <label>Device</label>
                    <div className="tr_data_text">{item.device}</div>
                  </div>
                  <div className="single_data">
                    <label>IP</label>
                    <div className="tr_data_text">{item.ip_address}</div>
                  </div>
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </div>
  );
};

export default AccountActivityMobileDataView;
