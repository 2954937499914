import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
const BetHistoryMobileDataView = (props) => {
  const [gameTransactions, setGameTransactions] = useState({});
  useEffect(() => {
    setGameTransactions(props.dataSet);
  }, [props]);

  return (
    <div className="transactions mobile_tr_view">
      {gameTransactions.length > 0 && (
        <Accordion>
          {gameTransactions.map((game, index) => (
            <Accordion.Item eventKey={index}>
              <Accordion.Header>
                <div className="mobile_tr_heading">
                  <div className="single_data">
                    <label>Date</label>
                    <div className="tr_data">{game.record_date}</div>
                  </div>
                  <div className="single_data">
                    <label>Game</label>
                    <div className="tr_data_text">{game.game_name}</div>
                  </div>
                </div>
              </Accordion.Header>
              <Accordion.Body>
                <div className="tr_data">
                  <div className="single_data">
                    <label>Round ID</label>
                    <div className="tr_data_text">{game.transaction_id}</div>
                  </div>
                  <div className="single_data">
                    <label>Amount</label>
                    <div className="tr_data_text">
                      {game.amount_bonus + game.amount_cash}
                    </div>
                  </div>
                  <div className="single_data">
                    <label>Coin Type</label>
                    <div className="tr_data_text">
                      {game.using_bonus == "Bonus" ? "Gold Coin" : "Sweepstake Coin"}
                    </div>
                  </div>
                  <div className="single_data">
                    <label>Play</label>
                    <div className="tr_data_text">{game.type}</div>
                  </div>
                  {/* <div className="single_data">
                    <label>Multiplier</label>
                    <div className="tr_data_text">0.00X</div>
                  </div> */}
                </div>
              </Accordion.Body>
            </Accordion.Item>
          ))}
        </Accordion>
      )}
    </div>
  );
};

export default BetHistoryMobileDataView;
