import React, { useState, useEffect } from "react";
import api from "services/api";
import Spinner from "elements/Spinner";
import { Helmet } from "react-helmet";
import "styles/_georestrition.scss";
import { __ } from "services/Translator";

const GeoRestriction = (props) => {
  const [isAllowed, setIsAllowed] = useState(null);
  const [blackListPage, setBlackListPage] = useState({});

  const geoPing = async () => {
    var response = await api.get("/geo-ping");
    if (response.status === 403) {
      setIsAllowed(false);
    } else if (response.status === 200) {
      setIsAllowed(true);
    } else {
      setTimeout(() => {
        geoPing();
      }, 2000);
    }
  };

  const getBlackListPage = async () => {
    var response = await api.get("/blacklist-page");
    if (response.status === 200) {
      setBlackListPage(response.data);
    }
  };

  useEffect(() => {
    geoPing();
  }, []);

  useEffect(() => {
    if (isAllowed === false) {
      getBlackListPage();
    }
  }, [isAllowed]);

  return isAllowed === null ? (
    <Spinner />
  ) : isAllowed === true ? (
    <>{props.children}</>
  ) : (
    <>
      <Helmet>
        <title>{__("Location Restriction!")}</title>
        <meta
          name="description"
          content={__("Location restricted from playing!")}
        />
      </Helmet>
      <div className="blacklist_page">
        <div className="logo">
          <img src={blackListPage?.logo} />
        </div>
        <h1 className="title">{__("Location Restriction!")}</h1>
        <p className="sub_text">
          {__(
            "Due to legal reasons, we cannot accept players residing in the country you are currently in."
          )}
        </p>

        <div className="page_content">
          <div className="left_content">
            <div className="content_icon">
              <i class="fa fa-globe" aria-hidden="true"></i>
            </div>
            <h4 className="content_title">
              {__("Not in a restricted region?")}
            </h4>
            <p className="content">
              {__(
                "If you are using proxy service or VPN to access the casino, try turning off and reload the page."
              )}
            </p>
          </div>
          <div className="right_content">
            <div className="content_icon">
              <i class="fa fa-envelope" aria-hidden="true"></i>
            </div>
            <h4 className="content_title">
              {__("Do you need to contact us?")}
            </h4>
            <p className="content">
              {__(
                "Should you have any questions or feel that this block is affecting you due to a technical error, please don't hesitate to contact us and we'll work it out together."
              )}
              <div className="contact_mail">
                <a href={"mailto:" + blackListPage?.email}>
                  {blackListPage?.email}
                </a>
              </div>
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default GeoRestriction;
