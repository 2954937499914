import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory } from "react-router-dom";
import auth from "/services/auth";
import ls from "services/ls";
import AppContext from "contexts/AppContext";
import api from "services/api";

import { Button } from "react-bootstrap";
import { __ } from "services/Translator";

import FacebookLogin from "react-facebook-login";
import config from "configs/config.js";
import jwt_decode from "jwt-decode";
import swal from "sweetalert2";

const SocialLogin = (props) => {
  // const menuOpen = props.menuOpen;
  //Auth State
  const history = useHistory();
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const { updateUserAuthStatus } = React.useContext(AppContext);
  const [loginData, setLoginData] = useState({});
  const socialLoginAction = async (loginData) => {
    var response = await api.post("/social-login", loginData);
    console.log("loginDataloginData", loginData);
    if (response.data.status == "success") {
      await auth.login(response.data.user);
      updateUserAuthStatus(response.data.user);
      history.push("/");
    } else {
      // setErrors(response.data.errors);
      return new swal({
        className: "custom_swal",
        title: __("Failed!"),
        text: response.data.message,
        icon: "error",
      });
    }
  };

  useEffect(() => {
    // socialLoginAction();
  }, [props]);

  const [initialSettings, setInitialSettings] = useState({
    facebook_login_enabled: 0,
    google_login_enabled: 0,
    apple_login_enabled: 0,
  });
  const getInitialSettings = async (ev) => {
    var response = await api.get("/initial-settings");
    setInitialSettings(response.data);
  };

  useEffect(() => {
    getInitialSettings();
  }, []);

  //Social Login
  const [spinner, setSpinner] = useState(false);
  const [showSocialRegTerms, setShowSocialRegTerms] = useState(false);
  const [socialRegisterData, setSocialRegisterData] = useState({
    name: "",
    email: "",
    socialId: "",
    provider: "",
    agreeToTerms: 0,
    acceptCountryLimitation: 0,
  });
  const responseFacebook = (response) => {
    // console.log(response);
    setSocialRegisterData({
      name: response.name,
      email: response.email,
      socialId: response.userID,
      provider: "Facebook",
      agreeToTerms: 0,
      acceptCountryLimitation: 0,
    });
    setRegisterData({
      name: response.name,
      email: response.email,
      socialId: response.userID,
      provider: "Facebook",
      agreeToTerms: 0,
      acceptCountryLimitation: 0,
    });
    // setSpinner(false);
    // console.log("socialRegisterData", socialRegisterData);
  };

  const componentClicked = (response) => {
    // console.log(response);
    setSpinner(true);
  };

  //New google login functionality
  const responseGoogle = (response) => {
    console.log(response);
    var userObject = jwt_decode(response.credential);
    // console.log(userObject.name);
    setSocialRegisterData({
      name: userObject.name,
      email: userObject.email,
      socialId: userObject.sub,
      provider: "Google",
      agreeToTerms: 0,
    });

    setRegisterData({
      name: userObject.name,
      email: userObject.email,
      socialId: userObject.sub,
      provider: "Google",
      agreeToTerms: 0,
    });
    props.setSpinner(false);
  };

  useEffect(() => {
    console.log("alert", "yes");
    if (window.google) {
      /* global google*/
      window.google.accounts.id.initialize({
        client_id: config.GoogleClientId,
        // "206987246875-k2roobn2stchbhvjhvs3t7e33dabfn9b.apps.googleusercontent.com",
        // "918922690152-tk90kb6fiq5ssekf7fcnn2d3hfrkvkkv.apps.googleusercontent.com",
        // client_id: config.GoogleClientId,
        callback: responseGoogle,
      });

      window.google.accounts.id.renderButton(
        document.getElementById("googleLogin"),
        // document.getElementsByClassName("googleLoginButton"),
        {
          theme: "outline",
          type: "icon",
          shape: "circle",
          size: "small",
          // width: 20,
        }
      );

      // window.google.accounts.id.renderButton(
      //   document.getElementById("googleLogin"), // This element should exist
      //   {
      //     theme: "outline",
      //     size: "large",
      //   }
      // );

      window.google.accounts.id.renderButton(
        document.getElementById("bannerGoogleLogin"),
        // document.getElementsByClassName("googleLoginButton"),
        {
          type: "icon",
          width: 30,
          height: 30,
          theme: "filled_black",
        }
      );
    }
  }, [Math.random()]);

  const triggerGoogleLogin = () => {
    window.google.accounts.id.prompt();
  };

  const [socialLoginData, setSocialLoginData] = useState({});
  const [applyLogin, setApplyLogin] = useState(false);
  const checkAvailability = async (ev) => {
    if (socialRegisterData.email.length > 0) {
      var response = await api.post(
        "/check-social-availability",
        socialRegisterData
      );

      // console.log(response.data);
      if (response.data.status == "Not Found") {
        setShowSocialRegTerms(true);
        // swal({
        //   title: "warning!",
        //   text: "Something went wrong. please try again.",
        //   icon: "warning",
        // });
      } else if (response.data.status == "Already Registered") {
        // setShowSocialRegTerms(true);

        setSocialLoginData({
          email: socialRegisterData.email,
          socialId: socialRegisterData.socialId,
          provider: socialRegisterData.provider,
        });

        setLoginData({
          email: socialRegisterData.email,
          socialId: socialRegisterData.socialId,
          provider: socialRegisterData.provider,
        });
        socialLoginAction({
          email: socialRegisterData.email,
          socialId: socialRegisterData.socialId,
          provider: socialRegisterData.provider,
        });
        setApplyLogin(true);
      } else if (response.data.status == "Email Already Registered") {
        // setShowSocialRegTerms(true);
        setSocialLoginData({
          email: socialRegisterData.email,
          socialId: socialRegisterData.socialId,
          provider: socialRegisterData.provider,
        });
        setLoginData({
          email: socialRegisterData.email,
          socialId: socialRegisterData.socialId,
          provider: socialRegisterData.provider,
        });
        socialLoginAction({
          email: socialRegisterData.email,
          socialId: socialRegisterData.socialId,
          provider: socialRegisterData.provider,
        });
        setApplyLogin(true);
      } else {
        setSpinner(false);
      }
    }
  };

  useEffect(() => {
    if (socialRegisterData.provider != "") {
      checkAvailability();
    }
  }, [socialRegisterData]);

  //Social Registration terms area

  const [errors, setErrors] = useState({});
  const [registerData, setRegisterData] = useState({
    name: "",
    email: "",
    socialId: "",
    provider: "",
    agreeToTerms: 0,
    acceptCountryLimitation: 0,
  });
  const updateRegisterForm = (field, value) => {
    setRegisterData({ ...registerData, [field]: value });
    // console.log('setRegisterDatasetRegisterData', registerData);
  };

  const registerInputChange = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;

    console.log("targettargettarget", target);
    if (field == "agreeToTerms") {
      var checked = ev.target.checked;
      if (checked) {
        value = 1;
      } else {
        value = 0;
      }
    }
    if (field == "acceptCountryLimitation") {
      var checked = ev.target.checked;
      if (checked) {
        value = 1;
      } else {
        value = 0;
      }
    }

    updateRegisterForm(field, value);
  };

  const registerValidateForm = () => {
    var valid = true;
    var errorsTmp = {};
    console.log("registerData", registerData);
    if (!registerData.agreeToTerms) {
      errorsTmp = {
        ...errorsTmp,
        agreeToTerms: __("Please read & accept terms & privacy policy"),
      };
      valid = false;
    }
    // if (!registerData.acceptCountryLimitation) {
    //   errorsTmp = {
    //     ...errorsTmp,
    //     acceptCountryLimitation: __("Please read & accept country policy"),
    //   };
    //   valid = false;
    // }
    setErrors(errorsTmp);
    return valid;
  };

  const registrationAction = async (ev) => {
    ev.preventDefault();
    var valid = registerValidateForm();
    if (valid) {
      var response = await api.post("/social-signup", registerData);
      // console.log(response.data);
      if (response.data.status == "success") {
        // history.push("/login");
        setApplyLogin(true);
        setLoginData({
          email: registerData.email,
          socialId: registerData.socialId,
          provider: registerData.provider,
        });
        socialLoginAction({
          email: registerData.email,
          socialId: registerData.socialId,
          provider: registerData.provider,
        });
      } else {
        setErrors(response.data.errors);
      }
    }
  };

  useEffect(() => {
    setRegisterData(socialRegisterData);
  }, []);
  return (
    <>
      <div className="socialLoginArea">
        {!showSocialRegTerms && (
          <>
            {initialSettings.google_login_enabled && (
              <>
                {props.callFrom == "banner" && (
                  <>
                    <div className="googleLoginBtn">
                      <div
                        id="bannerGoogleLogin"
                        className="googleLoginButton"
                      ></div>
                      <div className="clearfix"></div>
                    </div>
                  </>
                )}
                {props.callFrom == "modal" && (
                  <>
                    <div
                      onClick={triggerGoogleLogin}
                      className="googleLoginBtn"
                    >
                      <div id="googleLogin" className="googleLoginButton"></div>
                      <div className="clearfix"></div>
                    </div>
                  </>
                )}
              </>
            )}
            {initialSettings.facebook_login_enabled && (
              <>
                <div className="facebookLoginBtn">
                  <FacebookLogin
                    appId={config.FBAppId}
                    autoLoad={false}
                    fields="name,email,picture"
                    textButton=""
                    callback={responseFacebook}
                    onClick={componentClicked}
                    cssClass="login_btn facebook_btn"
                    icon="fab fa-facebook facebook_icon"
                  ></FacebookLogin>
                </div>
              </>
            )}
          </>
        )}

        {showSocialRegTerms && (
          <div className="registerTermsArea">
            <div className="form_area">
              <div className="common_headline text-center">
                {__("Please agree to the following terms")}
              </div>
              <div className="input_item">
                <div className="reg_check_area">
                  <input
                    type="checkbox"
                    className="stepTerms"
                    id="ageConfirmation"
                    // value={registrationForm.ageConfirmation}
                    name="agreeToTerms"
                    onChange={registerInputChange}
                  />
                  <label for="ageConfirmation" className="check_area">
                    <div className="check_check_and_text_area">
                      <div className="check_box">
                        <div className="check_checked_box"></div>
                      </div>
                      <div className="check_text">
                        {__(
                          "I declare that I am 18 years or older, located in a permitted territory and have no self-exclusions."
                        )}
                        <span className="requried_icon">*</span>
                      </div>
                    </div>
                  </label>
                  <div className="errorMsg">{errors.agreeToTerms}</div>
                </div>
              </div>

              <div className="login_button">
                <Button
                  onClick={registrationAction}
                  className="commonBtn purpleBtn"
                >
                  {__("Confirm")}
                </Button>
              </div>
              <br />
              <div className="login_button d-none">
                <Button className="commonBtn purpleBtn">{__("Back")}</Button>
              </div>
            </div>
          </div>
        )}
      </div>
      <div className="or_text_section ">
        <div className="dash"></div>
        <div className="text">{__("or log in with")}</div>
        <div className="dash"></div>
      </div>
    </>
  );
};

export default SocialLogin;
