import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Translator, { __ } from "services/Translator";
import api from "services/api";
import { Accordion } from "react-bootstrap";

export default function StepRightUp(props) {
  const stepUpDesc = [
    {
      paragraph:
        "Looking for some casino-style fun without spending a dime? Sweeplay is your answer! Imagine this: a treasure chest filled with free slots and jackpots waiting for you—no downloads required. Jump into the action from any device, anytime, anywhere.",
    },
    {
      paragraph:
        "Getting started with Sweeplay is super easy. Just create your free account, and you’re ready to go! We offer an extensive collection of games, with new ones added almost weekly. Whether you’re into Blackjack, Roulette, or our magical unicorn slots, there’s always something thrilling in store for you.",
    },
    {
      paragraph:
        "At Sweeplay, we pride ourselves on being the top destination for free online casino-style games. Thousands of players enjoy our games every week, and we’re always excited to welcome even more. Our community is lively and friendly, making it the perfect place to share in the fun.",
    },
    {
      paragraph:
        "Need assistance? Our Support Team is available 24/7 to help with any questions. Simply click the ‘contact’ button at the top of the page to reach out.",
    },
    {
      paragraph:
        "So, why wait? Join Sweeplay today and dive into a world of free casino-style games. Fun is just a click away!",
    },
  ];

  const [visibleTextCount, setVisibleTextCount] = useState(5);

  const handleShowMore = () => {
    setVisibleTextCount(visibleTextCount + 1);
  };

  useEffect(() => {
    if (window.innerWidth < 768) {
      setVisibleTextCount(2);
    }
  }, []);
  return (
    <>
      <Accordion className="homepageSeoAcr">
        <Accordion.Item eventKey="0">
          <Accordion.Header>Step Right Up to Sweeplay!</Accordion.Header>
          <Accordion.Body>
            <div className="homeElementBlock stepRightUp">
              <img
                className="blockBg desktop"
                alt=""
                src={require("assets/sweeplay/images/banners/step-up-banner.png")}
              />
              <img
                className="blockBg tab"
                alt=""
                src={require("assets/sweeplay/images/banners/step-up-banner-tab.png")}
              />
              <div className="homeBlockContent">
                <h1 className="sweepplay-title">Step Right Up to Sweeplay!</h1>

                {stepUpDesc.slice(0, visibleTextCount).map((desc, index) => (
                  <p key={index} className="sweepplay-paragraph">
                    {desc.paragraph}
                  </p>
                ))}
                {visibleTextCount < stepUpDesc.length && (
                  <div className="loadMoreArea">
                    <Link
                      hreflang={Translator.getSelectedLanguage().code}
                      className="loadMoreBtn slide-bottom"
                      onClick={handleShowMore}
                    >
                      Load More
                      <svg
                        width="24px"
                        height="24px"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                        <g
                          id="SVGRepo_tracerCarrier"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                          stroke="#CCCCCC"
                          stroke-width="0.048"
                        ></g>
                        <g id="SVGRepo_iconCarrier">
                          {" "}
                          <path
                            d="M12 14.5C11.9015 14.5005 11.8038 14.4813 11.7128 14.4435C11.6218 14.4057 11.5392 14.3501 11.47 14.28L8 10.78C7.90861 10.6391 7.86719 10.4715 7.88238 10.3042C7.89756 10.1369 7.96848 9.97954 8.08376 9.85735C8.19904 9.73515 8.352 9.65519 8.51814 9.63029C8.68428 9.6054 8.85396 9.63699 9 9.72003L12 12.72L15 9.72003C15.146 9.63699 15.3157 9.6054 15.4819 9.63029C15.648 9.65519 15.801 9.73515 15.9162 9.85735C16.0315 9.97954 16.1024 10.1369 16.1176 10.3042C16.1328 10.4715 16.0914 10.6391 16 10.78L12.5 14.28C12.3675 14.4144 12.1886 14.4931 12 14.5Z"
                            fill="#ffffff"
                          ></path>{" "}
                        </g>
                      </svg>
                    </Link>
                  </div>
                )}
              </div>
            </div>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </>
  );
}
