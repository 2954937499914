import { configureStore } from "@reduxjs/toolkit";
import userBalanceReducer from "stores/balance/userBalance";
import userAuthReducer from "stores/auth/userAuth";
import loginBonusReducer from "stores/bonus/loginBonus";

export default configureStore({
  reducer: {
    userBalance: userBalanceReducer,
    userAuth: userAuthReducer,
    loginBonus: loginBonusReducer,
  },
});
