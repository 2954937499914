import React, { useState, useEffect } from "react";
import api from "services/api";
import { Helmet } from "react-helmet";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { __ } from "services/Translator";


// components

import Banner from "elements/Banner";
import GamesFilter from "elements/GameElements/GamesFilter";
import CategoryGames from "elements/GameElements/CategoryGames";
import GameMenu from "elements/GameElements/GameMenu";
import HomeBanner from "elements/HomeBanner";

export default function Games(props) {
  const params = useParams();
  const location = useLocation();
  const history = useHistory();

  const gameTypeAPISlugs = {
    all: "all",
    slots: "video_slots",
    blackjack: "blackjack",
    "video-poker": "table_games",
    "live-dealer": "live_casino",
    "live-casino": "live_casino",
    "bonus-buy": "bonus_buys",
    megaways: "megaways",
    jackpot: "jackpot",
    "table-games": "table_games",
    popular: "popular",
    new: "new",
    search: "search_results",
    "provably-fair": "provably-fair",
  };

  const gameTitles = {
    slots: __("Slots"),
    blackjack: __("Blackjack"),
    "video-poker": __("Video Poker"),
    "live-dealer": __("Live Dealer"),
    "live-casino": __("Live Casino"),
    "bonus-buy": __("Bonus Buys"),
    megaways: __("Megaways"),
    jackpot: __("Jackpot Games"),
    "table-games": __("Table Games"),
    popular: __("Popular"),
    new: __("New"),
    search_results: __("Search Results"),
    "provably-fair": __("Provably Fair"),
  };

  const pageTitles = {
    slots: __("Sweeplay Casino | Choose slots from Microgaming, Netent, Pragmatic Play & More!"),
    "live-casino": __("Sweeplay Casino | See all our Live Casino Games with our partners at Evolution and more!"),
    "bonus-buy": __("Sweeplay Casino | Buy yourself a Bonus game with these Bonus Buys!"),
    megaways: __("Sweeplay Casino | All your favourite Megaways Games, all in one place!"),
    jackpot: __("Sweeplay Casino | Change your life with our huge Progressive slots!"),
    "table-games": __("Sweeplay Casino | Choose from Blackjack, Roulette, Baccarat and more!"),
    popular: __("Sweeplay Casino | See the most popular Slots with our players today!"),
    new: __("Sweeplay Casino | See our Newest Games, all in one handy place!"),
    search_results: __("Sweeplay Casino | Play our wide range of slots and table games!"),
    "provably-fair": __("Sweeplay Casino | Play our Provably fair Slots - Fairness is guaranteed!"),
    all: __("Sweeplay Casino | Play our wide range of slots and table games!"),
  };

  const metaDescriptions = {
    slots: __(
      "Sweeplay Casino! All of your favourite slots in one place including Fruit Party, Rick Wilde in Book of the Dead, Gates of Olympus and more from providers such as Pragmatic Play, Microgaming and Netent!"
    ),
    "live-casino": __(
      "Sweeplay Casino! A wide range of games you can play as if you were in the casino itself - interact with the Dealers and Croupiers and take part in our Game Shows such as Crazy Time - all thanks to our friends at Evolution!"
    ),
    "bonus-buy": __(
      "Sweeplay Casino! Fed up of playing Slots where you never get the feature? With these games, you can buy the feature and with it a real chance to win big!"
    ),
    megaways: __(
      "Sweeplay Casino! Megaways give you a huge numbers of ways to win, each spin is different - with massive Jackpots across many of the Slots, what are you waiting for? Play today!"
    ),
    jackpot: __(
      "Sweeplay Casino! These Progressive Jackpots are not to be sniffed at, with many into 7 figures, one win on any of these and your life will never be the same again!"
    ),
    "table-games": __(
      "Sweeplay Casino! All our popular Table Games in one section, choose from different variations of Blackjack, Roulette, Baccarat and more!"
    ),
    popular: __("Sweeplay Casino! See what Slots your fellow players are using most and join them on an epic journey!"),
    new: __("Sweeplay Casino! All our newest games in one handy section - check back regularly to see what is new!"),
    search_results: __(
      "Sweeplay Casino! With a huge range of Slots from some of your favourite providers such as Netent, Microgaming, Pragmatic Play and Hacksaw Gaming, and a wide range of all your favourite Table Games, there is no need to go anywhere else!"
    ),
    "provably-fair": __(
      "Sweeplay Casino! These Provably Fair slots have been tried and tested to ensure fairness for all - play them now, it could be you!"
    ),
    all: __(
      "Sweeplay Casino! With a huge range of Slots from some of your favourite providers such as Netent, Microgaming, Pragmatic Play and Hacksaw Gaming, and a wide range of all your favourite Table Games, there is no need to go anywhere else!"
    ),
  };

  const [gameType, setGameType] = useState(params.gameType ? params.gameType : "all");

  const [pagination, setPagination] = useState(null);
  const [games, setGames] = useState([]);
  const [gameSorting, setGameSorting] = useState(null);
  const [perPage, setPerPage] = useState(24);
  const [gameLoaded, setGameLoaded] = useState(0);
  const [totalGame, setTotalGame] = useState(0);

  const getGames = async (page) => {
    props.setSpinner(true);
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      game_types: [gameTypeAPISlugs[gameType]],
      page: page,
      game_sorting: gameSorting,
      providers: props.selectedProviders,
    });
    props.setSpinner(false);
    if (response.status === 200) {
      setIsSearchResultsShowing(false);
      props.setSearchStr("");
      if (page === 1) {
        setGames(response.data.games);
      } else {
        var realGameType = gameTypeAPISlugs[gameType];

        var gamesClone = JSON.parse(JSON.stringify(games));

        gamesClone[realGameType].gamelist = [
          ...gamesClone[realGameType].gamelist,
          ...response.data.games[realGameType].gamelist,
        ];
        setGames(gamesClone);
      }

      setGameLoaded(page === 1 ? response.data.loadedGameCount : gameLoaded + response.data.loadedGameCount);
      setTotalGame(response.data.gameCount);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }
    }
  };

  const onPageChange = (page) => {
    if (gameType === "search") {
      searchGames(page, null);
    } else {
      getGames(page);
    }
  };

  useEffect(() => {
    if (gameType === "search") {
      if (props.searchStr.length) {
        searchGames(1);
      } else {
        history.push("/games/all");
      }
    } else {
      getGames(1);
    }
  }, [gameSorting, gameType, props.searchStr, props.selectedProviders]);

  const searchGames = async (page) => {
    var perPage = 30;
    var serchParams = {
      search_query: props.searchStr,
      page: page,
      per_page: perPage,
      providers: props.selectedProviders,
    };

    var response = await api.post("/games/search", serchParams);
    if (response.status === 200) {
      setIsSearchResultsShowing(true);
      setGames(response.data.games);
      if (response.data.paginationData) {
        setPagination(response.data.paginationData);
      } else {
        setPagination(null);
      }

      // setGameLoaded(page * perPage);

      setGameLoaded(page === 1 ? response.data.loadedGameCount : gameLoaded + response.data.loadedGameCount);

      setTotalGame(response.data.gameCount);
    }
  };

  const [isSearchResultsShowing, setIsSearchResultsShowing] = useState(false);

  useEffect(() => {
    setGameType(params.gameType);
  }, [params]);

  return (
    <>
      <Helmet>
        <title>{pageTitles[gameType] ? pageTitles[gameType] : pageTitles["all"]}</title>
        <meta
          name="description"
          content={metaDescriptions[gameType] ? metaDescriptions[gameType] : metaDescriptions["all"]}
        />
      </Helmet>

      <div className="container-xxl">
        <div className="gamesContainer slideUpAnimation">
          {/* <HomeBanner {...props} /> */}
          <GameMenu {...props} />
          <CategoryGames
            gameList={games[gameTypeAPISlugs[gameType] ?? "all"]?.gamelist ?? []}
            categoryName={gameTitles[gameType] ?? ""}
            isSearchResults={isSearchResultsShowing}
            category={gameType}
            onPageChange={onPageChange}
            pagination={pagination}
            setGameSorting={setGameSorting}
            gameSorting={gameSorting}
            showPagination={true}
            gameLoaded={gameLoaded}
            totalGame={totalGame}
            providers={props.providers}
            selectedProviders={props.selectedProviders}
            setSelectedProviders={props.setSelectedProviders}
            isAuthenticated={props.isAuthenticated}
            setSearchStr={props.setSearchStr}
            {...props}
          />
        </div>
      </div>
    </>
  );
}
