import React, { useState, useEffect, useRef, useContext } from "react";
// import { liveChatUrl } from "../configs/config.js";
import api from "services/api";
import { Modal } from "react-bootstrap";
import { Link, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Translator, { __ } from "services/Translator";

// import "../../../styles/_header_wallet.scss";
import Spinner from "elements/Spinner";

const SweepCoinBalance = (props) => {
  const __ = (text) => text;
  const dispatch = useDispatch();
  //GamePlay Page detection
  const location = useLocation();

  const [showInPlay, setShowInPlay] = useState(false);
  const playPath = location.pathname.split("/");
  const [spinner, setSpinner] = useState(false);

  useEffect(() => {
    setShowInPlay(playPath.includes("play"));
  }, [playPath]);

  const closeWalletModal = () => {
    setShowWalletModal(false);
  };

  //Deposit functinoality START's here
  const [depositAmount, setDepositAmount] = useState(0);
  const [depositMethods, setDepositMethods] = useState([]);
  const [depositMethod, setDepositMethod] = useState(null);

  const [depositData, setDepositData] = useState({
    amount: 0,
    method: "",
    campaign: "no_bonus",
    planId: 0,
  });

  const getDepositMethods = async (amount) => {
    var effectiveAmount = depositAmount;
    // console.log("effectiveAmount", effectiveAmount);
    if (!effectiveAmount && amount) {
      effectiveAmount = amount;
    }
    if (effectiveAmount) {
      var response = await api.post("/deposit/methods", {
        amount: effectiveAmount,
      });

      if (response.status === 200) {
        setSpinner(false);
        setDepositMethods(response.data.methods);
        if (!response.data.methods.filter((method) => method.method_id === depositMethod?.method_id).length) {
          setDepositMethod(null);
        }

        return response.data.methods;
      }
    }
  };

  useEffect(() => {
    setSpinner(true);
    getDepositMethods();
  }, [depositAmount]);
  //Deposit functionality END's here

  //Withdrawal functionality START's here
  const [showWalletModal, setShowWalletModal] = useState(false);
  const buyCoins = async () => {
    if (props.buyGoldPlanData.openWallet) {
      if (props.buyGoldPlanData.planPrice) {
        setDepositAmount(props.buyGoldPlanData.planPrice);
        setDepositData({
          ...depositData,
          ["amount"]: props.buyGoldPlanData.planPrice,
          ["planId"]: props.buyGoldPlanData.planId,
        });
        setShowWalletModal(true);
      } else {
        var depData = {
          ...depositData,
          ["amount"]: props.buyGoldPlanData.planPrice,
          ["planId"]: props.buyGoldPlanData.planId,
        };

        props.setSpinner(true);
        var response = await api.post("/sweepcoin-purchase", depData);
        props.setSpinner(false);
        console.log("depositResponse", response);
        if (response.status === 200 && response.data.status === "APPROVED") {
          props.showAlert(__("Success"), __(response.data.message));
          closeWalletModal();
        } else {
          props.showAlert("Warning!!", response.data.message);
        }
      }
    }
  };

  useEffect(() => {
    buyCoins();
  }, [props.buyGoldPlanData]);

  useEffect(() => {
    // console.log("depositData", depositData);
  }, []);

  const submitDeposit = async (event) => {
    event.preventDefault();

    props.setSpinner(true);

    depositData.method = depositMethod?.method_id;

    props.setSpinner(true);
    var response = await api.post("/sweepcoin-purchase", depositData);
    props.setSpinner(false);
    // console.log("depositResponse", response);
    if (response.status === 200) {
      if (response.data.status === "additional_fields") {
      } else if (response.data.status === "redirect") {
        window.location.href = response.data.redirectUrl;
      }
    } else {
      props.showAlert("Warning!!", response.data.message);
    }
  };

  return (
    <>
      <Modal
        className="backdrop_buy"
        show={showWalletModal}
        dialogClassName="buy-modal-width"
        onHide={closeWalletModal}
      >
        <Modal.Body>
          <div onClick={closeWalletModal} className="modal_close_reg">
            <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M8.68964 12.2073L0.9655 4.78928C0.551706 4.39188 0.275845 3.79579 0.275845 3.1997C0.275845 2.60361 0.551706 2.00752 0.9655 1.61012C1.86205 0.7491 3.37929 0.7491 4.27584 1.61012L12 9.02815L19.7241 1.61012C20.6207 0.7491 22.1379 0.7491 23.0345 1.61012C23.4483 2.00752 23.7241 2.60361 23.7241 3.1997C23.7241 3.79579 23.4483 4.39188 23.0345 4.78928L15.3103 12.2073L23.0345 19.6253C23.4483 20.0227 23.7241 20.6188 23.7241 21.2149C23.7241 21.811 23.4483 22.4071 23.0345 22.8045C22.6207 23.2019 22 23.4668 21.3793 23.4668C20.7586 23.4668 20.1379 23.2019 19.7241 22.8045L12 15.3865L4.27584 22.8045C3.37929 23.6655 1.86205 23.6655 0.9655 22.8045C0.551706 22.4071 0.275845 21.811 0.275845 21.2149C0.275845 20.6188 0.551706 20.0227 0.9655 19.6253L8.68964 12.2073Z"
                fill="#10294F"
              />
            </svg>
          </div>
          <div className="coinItemsContainer">
            <div className="heading">{__("Order Summary")}</div>
          </div>
          <div class="buy_coins">
            <div className={"buy_coins_content select_payment_method"}>
              {spinner && <Spinner />}

              <div className="common_headline text-center">
                <small>
                  {props.buyGoldPlanData.GC + __(" gold coins")}
                  {props.buyGoldPlanData.SC ? " and " + props.buyGoldPlanData.SC + __(" sweepstake coins free") : "."}
                </small>
              </div>

              <div className="itemsArea">
                <div className="stores">
                  {depositMethods.length > 0 &&
                    depositMethods.map((method, index) => (
                      <div
                        className={"single_store " + (depositMethod?.method_id === method.method_id ? "selected" : "")}
                        onClick={() => {
                          setDepositMethod(method);
                        }}
                      >
                        <div className="gcVal d-none">
                          <input
                            id={"all_" + method.id}
                            onChange={() => {
                              setDepositMethod(method);
                            }}
                            type="radio"
                            checked={method?.method_id === depositMethod?.method_id}
                          />
                        </div>
                        <div className="scVal">
                          <img className="method_logo" alt="" src={method.logo} />
                        </div>
                        <div className="actionArea white">{method.method}</div>
                      </div>
                    ))}

                  <br />
                  <div className="text-center">
                    <Link
                      hreflang={Translator.getSelectedLanguage().code}
                      className="commonBtn purpleBtn bigBtn"
                      onClick={submitDeposit}
                    >
                      Submit
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SweepCoinBalance;
