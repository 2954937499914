import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";
import auth from "../services/auth";
import ls from "local-storage";
import AppContext from "../contexts/AppContext";
import api from "../services/api";
import emailValidate from "../services/emailValidate";
import moneyFormatter from "../services/moneyFormatter";
import { Modal, Button } from "react-bootstrap";
import Spinner from "elements/Spinner";
import Translator, { __ } from "services/Translator";

const BuyGoldCoins = (props) => {
  // buymodal
  const [buyModal, setBuyModal] = useState(false);
  const [spinner, setSpinner] = useState(false);

  const closeBuyModal = () => {
    setBuyModal(false);
  };

  //getBuyGoldCoinPlans functionality START'S here
  const [sweepstakeProductSD, setSweepstakeProductSD] = useState([]);
  const [sweepstakeProduct, setSweepstakeProduct] = useState([]);
  const getBuyGoldCoinPlans = async () => {
    var response = await api.get("/get-buy-gold-coin-plans");

    if (response.status === 200 && response.data) {
      setSpinner(false);
      setSweepstakeProductSD(response.data.sweepstakeProductSD);
      setSweepstakeProduct(response.data.sweepstakeProduct);
    }
  };

  useEffect(() => {
    if (props.showBuyModal) {
      setBuyModal(props.showBuyModal);
      setSpinner(true);
      getBuyGoldCoinPlans();
    }
  }, [props.showBuyModal]);

  useEffect(() => {
    if (props.showBuyModalG) {
      setBuyModal(props.showBuyModalG);
      setSpinner(true);
      getBuyGoldCoinPlans();
    }
  }, [props.showBuyModalG]);

  const [buyPlanData, setBuyPlanData] = useState({
    planId: "",
    planPrice: "",
    openWallet: false,
    GC: 0,
    SC: 0,
  });

  useEffect(() => {
    props.callDepositModal(buyPlanData);
  }, [buyPlanData]);

  return (
    <>
      <Modal className="" dialogClassName="buy-modal-width" show={buyModal}>
        <Modal.Body>
          <div
            onClick={() => {
              setBuyModal(0);
            }}
            className="modal_close_reg"
          >
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.68964 12.2073L0.9655 4.78928C0.551706 4.39188 0.275845 3.79579 0.275845 3.1997C0.275845 2.60361 0.551706 2.00752 0.9655 1.61012C1.86205 0.7491 3.37929 0.7491 4.27584 1.61012L12 9.02815L19.7241 1.61012C20.6207 0.7491 22.1379 0.7491 23.0345 1.61012C23.4483 2.00752 23.7241 2.60361 23.7241 3.1997C23.7241 3.79579 23.4483 4.39188 23.0345 4.78928L15.3103 12.2073L23.0345 19.6253C23.4483 20.0227 23.7241 20.6188 23.7241 21.2149C23.7241 21.811 23.4483 22.4071 23.0345 22.8045C22.6207 23.2019 22 23.4668 21.3793 23.4668C20.7586 23.4668 20.1379 23.2019 19.7241 22.8045L12 15.3865L4.27584 22.8045C3.37929 23.6655 1.86205 23.6655 0.9655 22.8045C0.551706 22.4071 0.275845 21.811 0.275845 21.2149C0.275845 20.6188 0.551706 20.0227 0.9655 19.6253L8.68964 12.2073Z"
                fill="#10294F"
              />
            </svg>
          </div>
          <div className="coinItemsContainer">
            <div className="heading">{"Select the coin package"}</div>
          </div>

          <div className="buy_coins">
            <div className="buy_coins_content">
              <div className="common_headline text-center"></div>

              <div className="itemsArea">
                <div className="stores">
                  <div className="single_store storeHeading">
                    <div className="gcVal">{__("Gold Coins")}</div>
                    <div className="scVal">{__("Free Sweeplay Coins")}</div>
                    <div></div>
                  </div>
                  {sweepstakeProductSD.length > 0 &&
                    sweepstakeProductSD.map((value, index) => (
                      <div className="single_store bestDeal">
                        <div className="gcVal">
                          <label>{__("Best Deal")}</label>
                          <img
                            width="23"
                            height="23"
                            src={
                              require("assets/sweeplay/images/cashierModal/gold-coin-sweeplay.svg")
                                .default
                            }
                          />{" "}
                          <span className="gcNumber">
                            {moneyFormatter.numberFormat(value.gc)}
                          </span>
                        </div>
                        <div className="scVal">
                          {value.coin_increase > 0 && (
                            <div className="moreBanner">
                              {value.coin_increase}
                              <span>% {__("More")}</span>
                            </div>
                          )}

                          {value.sc > 0 && (
                            <div className="value">
                              <img
                                width="23"
                                height="23"
                                src={
                                  require("assets/sweeplay/images/cashierModal/sweeplay-coin.svg")
                                    .default
                                }
                              />{" "}
                              {moneyFormatter.numberFormat(value.sc)}
                              {value.sc_extra
                                ? "+ " + value.sc_extra + " Extra"
                                : ""}
                            </div>
                          )}
                        </div>
                        <div className="actionArea">
                          <Link
                            hreflang={Translator.getSelectedLanguage().code}
                            className="commonBtn bannerBtn buyCoinBtn"
                            onClick={() => {
                              setBuyModal(false);
                              setBuyPlanData({
                                ...buyPlanData,
                                planId: value.id,
                                planPrice: value.price,
                                openWallet: true,
                                SC:
                                  value.sc +
                                  " " +
                                  (value.sc_extra
                                    ? "+ " + value.sc_extra + " Extra"
                                    : ""),
                                GC: value.gc,
                              });
                            }}
                          >
                            <span>Buy</span>{" "}
                            {moneyFormatter.balanceFormat(value.price, "USD")}
                          </Link>
                        </div>
                      </div>
                    ))}

                  {sweepstakeProduct.length > 0 &&
                    sweepstakeProduct.map((value, index) => (
                      <div className="single_store">
                        <div className="gcVal">
                          <img
                            width="23"
                            height="23"
                            src={
                              require("assets/sweeplay/images/cashierModal/gold-coin-sweeplay.svg")
                                .default
                            }
                          />

                          {moneyFormatter.numberFormat(value.gc)}
                        </div>
                        <div className="scVal">
                          {value.coin_increase > 0 && (
                            <div className="moreBanner">
                              {value.coin_increase}
                              <span>% {__("More")}</span>
                            </div>
                          )}
                          {value.sc > 0 && (
                            <div className="value">
                              <img
                                width="23"
                                height="23"
                                src={
                                  require("assets/sweeplay/images/cashierModal/sweeplay-coin.svg")
                                    .default
                                }
                              />
                              {moneyFormatter.numberFormat(value.sc)}
                              {value.sc_extra
                                ? "+ " + value.sc_extra + " Extra"
                                : ""}
                            </div>
                          )}
                        </div>
                        <div className="actionArea">
                          <Link
                            hreflang={Translator.getSelectedLanguage().code}
                            className="commonBtn bannerBtn buyCoinBtn"
                            onClick={() => {
                              setBuyModal(false);
                              setBuyPlanData({
                                ...buyPlanData,
                                planId: value.id,
                                planPrice: value.price,
                                openWallet: true,
                                SC:
                                  value.sc +
                                  " " +
                                  (value.sc_extra
                                    ? "+ " + value.sc_extra + " Extra"
                                    : ""),
                                GC: value.gc,
                              });
                            }}
                          >
                            <span>Buy</span>{" "}
                            {moneyFormatter.balanceFormat(value.price, "USD")}
                          </Link>
                        </div>
                      </div>
                    ))}
                </div>
                <p className="termsText">
                  {__(
                    "It's always Free to play our Sweeplay Coins games. No Purchase necessary!"
                  )}{" "}
                  <Link
                    hreflang={Translator.getSelectedLanguage().code}
                    to="/promotions"
                  >
                    {__("Promotion")}
                  </Link>{" "}
                  {__("and")} {__("the")}{" "}
                  <Link
                    hreflang={Translator.getSelectedLanguage().code}
                    to="/terms-and-conditions"
                  >
                    {__("Term & Conditions")}
                  </Link>
                </p>
              </div>
            </div>
            <div className="clearfix"></div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BuyGoldCoins;
