import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useParams } from "react-router-dom";

import Faqs from "../components/Faqs";
import Swal from "sweetalert2";

//New structure

import GameSlider from "elements/GameElements/GameSlider";
import CategoryGameContainer from "elements/GameElements/CategoryGameContainer";
import Winner from "../components/Winner";
import HomeBanner from "elements/HomeBanner";
import StepRightUp from "elements/home/StepRightUp";
import BringOn from "elements/home/BringOn";
import GameMenu from "elements/GameElements/GameMenu";

export default function Landing(props) {
  const [seconds, setSeconds] = useState(4083958);
  const params = useParams();
  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 60);
    return () => clearInterval(interval);
  }, []);

  const [categoryName, setCategoryName] = useState("Slots");
  const __ = (text) => text;

  useEffect(() => {
    if (params.status == "true") {
      Swal.fire({
        title: "Success!",
        text: "Successfully bought coins package.",
        icon: "success",
        background: "#2e2e2e", // Dark background color
        color: "#fff", // White text color
        customClass: {
          popup: "dark-theme-popup",
          title: "dark-theme-title",
          content: "dark-theme-content",
        },
      });
    } else if (params.status == "false") {
      Swal.fire({
        title: "Warning!",
        text: "Buying coins package failed!",
        icon: "warning",
        background: "#2e2e2e", // Dark background color
        color: "#fff", // White text color
        customClass: {
          popup: "dark-theme-popup",
          title: "dark-theme-title",
          content: "dark-theme-content",
        },
      });
    }
  }, [params.status]);

  return (
    <>
      <div className="container-xxl">
        {/* <Banner {...props} /> */}
        <HomeBanner {...props} />
        {/* <TopShortMenu /> */}

        {/* <div className="gameHeading">{__("Sweeplay Games")}</div> */}
        {/* <GameSlider
          categoryName="Popular"
          category="video_slots"
          perPage="20"
          {...props}
        /> */}
        {<GameMenu callFromSlider={false} {...props} />}

        <CategoryGameContainer {...props} />
        <Winner {...props} />

        <br />
        <GameSlider
          categoryName="New"
          menuBar={false}
          category="new"
          perPage="20"
          title={true}
          {...props}
        />
        <br />
        <GameSlider
          categoryName="Table Games"
          menuBar={false}
          category="table_games"
          perPage="20"
          title={true}
          {...props}
        />
        <br />

        <StepRightUp {...props} />

        {/* <Faqs heading={true} {...props} /> */}
        <BringOn {...props} />
      </div>
    </>
  );
}
