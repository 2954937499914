import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import api from "services/api";

const GameItem = ({ game, isAuthenticated, props }) => {
  // const [visible, setVisible] = useState(true);
  const [processed, setProcessed] = useState(false);
  // const loaderImage = require("assets/sweeplay/images/games/game-loader.gif");
  const loaderImage = require("assets/sweeplay/images/logos/logo.svg").default;
  const [gameSnapshotUrl, setGameSnapshotUrl] = useState(loaderImage);
  const defaultImage = require("assets/sweeplay/images/games/default.png");

  const hideNoImageFoundGames = () => {
    setGameSnapshotUrl(defaultImage);
  };

  const fixImageRatio = (event) => {
    if (!processed) {
      const img = event.target;
      img.src = game.game_snapshot_url;
      img.onload = () => {
        const width = img.width;
        const height = width * 1;
        img.height = height;
        setGameSnapshotUrl(game.game_snapshot_url);
        setProcessed(true);
      };
    }
  };

  useEffect(() => {
    setGameSnapshotUrl(loaderImage);
    setProcessed(false);
  }, [game.id, loaderImage]);

  const [isFavorite, setIsFavorite] = useState(game.is_favorite_game);

  const toggleFavoriteGame = async () => {
    const response = await api.post("/toggle-favorite-game", {
      gameId: game.id,
    });
    if (response.status === 200) {
      setIsFavorite(response.data.status === "added");
    }
  };

  return (
    <div className="gameItemBlock">
      <div className="game_thumb">
        <Link
          hrefLang={Translator.getSelectedLanguage().code}
          to={"/play/" + game.slug}
        >
          <img
            onLoad={fixImageRatio}
            src={gameSnapshotUrl}
            onError={hideNoImageFoundGames}
            alt={game.game_name ?? ""}
          />
        </Link>
        <div className="game_overlay">
          {!!game.rtp && (
            <div className="rtp_info">
              <div className="left rtp_text">
                RTP: <span>{game.rtp}%</span>
              </div>
            </div>
          )}
          <h3 className="game_name">{game.game_name}</h3>
          <div className="game_action">
            {!isAuthenticated ? (
              <Link
                hreflang={Translator.getSelectedLanguage().code}
                onClick={() => {
                  props.setModalSignUpPropsData(true);
                  setTimeout(() => {
                    props.setModalSignUpPropsData(false);
                  }, 500);
                }}
                className="game_play_icon"
              >
                <i className="fas fa-play"></i>
                <span>{__("Sign Up")}</span>
              </Link>
            ) : (
              <Link
                hreflang={Translator.getSelectedLanguage().code}
                className="game_play_icon"
                to={"/play/" + game.slug + "/real"}
              >
                <i className="fas fa-play"></i>
                <span>{__("Play")}</span>
              </Link>
            )}
          </div>
          <div className="game_info">
            <div className="left">
              <h4 className="game_provider_name">{game.provider?.name}</h4>
            </div>
            <div className="right">
              {!isAuthenticated ? (
                <Link
                  hreflang={Translator.getSelectedLanguage().code}
                  onClick={() => {
                    props.setModalSignUpPropsData(true);
                    setTimeout(() => {
                      props.setModalSignUpPropsData(false);
                    }, 500);
                  }}
                  className="favorite_icon"
                >
                  <svg
                    width="20"
                    height="18"
                    viewBox="0 0 20 18"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clipPath="url(#clip0_2081_1449)">
                      <path
                        d="M5.4 0C2.4 0 0 2.4 0 5.4C0 6.4 0.5 7.3 0.9 7.9C1.3 8.5 1.8 8.9 1.8 8.9L9.5 16.6L10 17.1L10.5 16.6L18.2 9C18.2 9 20 7.4 20 5.4C20 2.4 17.6 0 14.6 0C12.1 0 10.6 1.5 10 2.1C9.4 1.5 7.8 0 5.4 0ZM5.4 1.4C7.5 1.4 9.5 3.5 9.5 3.5L10 4.1L10.5 3.5C10.5 3.5 12.5 1.4 14.6 1.4C16.8 1.4 18.5 3.2 18.5 5.3C18.5 6.4 17.2 7.9 17.2 7.9L10 15.2L2.8 7.9C2.8 7.9 2.5 7.6 2.1 7.1C1.7 6.6 1.4 5.9 1.4 5.4C1.4 3.2 3.2 1.4 5.4 1.4Z"
                        fill="#4698ED"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_2081_1449">
                        <rect width="20" height="17.2" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>
                </Link>
              ) : (
                <Link
                  hreflang={Translator.getSelectedLanguage().code}
                  onClick={toggleFavoriteGame}
                  className="favorite_icon"
                >
                  {isFavorite ? (
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_2081_3472)">
                        <path
                          d="M14.6 0C12.5 0 10.8 1.3 10 2C9.2 1.3 7.4 0 5.4 0C2.4 0 0 2.4 0 5.4C0 7.2 1.7 8.9 1.8 9L10 17.2L18.2 9C18.3 8.9 20 7.2 20 5.4C20 2.4 17.6 0 14.6 0Z"
                          fill="#4698ED"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2081_3472">
                          <rect width="20" height="17.2" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  ) : (
                    <svg
                      width="20"
                      height="18"
                      viewBox="0 0 20 18"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g clipPath="url(#clip0_2081_1449)">
                        <path
                          d="M5.4 0C2.4 0 0 2.4 0 5.4C0 6.4 0.5 7.3 0.9 7.9C1.3 8.5 1.8 8.9 1.8 8.9L9.5 16.6L10 17.1L10.5 16.6L18.2 9C18.2 9 20 7.4 20 5.4C20 2.4 17.6 0 14.6 0C12.1 0 10.6 1.5 10 2.1C9.4 1.5 7.8 0 5.4 0ZM5.4 1.4C7.5 1.4 9.5 3.5 9.5 3.5L10 4.1L10.5 3.5C10.5 3.5 12.5 1.4 14.6 1.4C16.8 1.4 18.5 3.2 18.5 5.3C18.5 6.4 17.2 7.9 17.2 7.9L10 15.2L2.8 7.9C2.8 7.9 2.5 7.6 2.1 7.1C1.7 6.6 1.4 5.9 1.4 5.4C1.4 3.2 3.2 1.4 5.4 1.4Z"
                          fill="#4698ED"
                        />
                      </g>
                      <defs>
                        <clipPath id="clip0_2081_1449">
                          <rect width="20" height="17.2" fill="white" />
                        </clipPath>
                      </defs>
                    </svg>
                  )}
                </Link>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default GameItem;
