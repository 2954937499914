import "./App.scss";
// import  "dis/bootstrap/less/bootstrap.less";
import React from "react";
import AuthGuard from "guards/AuthGuard";
import ls from "services/ls";
import { AppProvider } from "./contexts/AppContext";
import SecurityPopup from "elements/SecurityPopup";
import GeoRestriction from "elements/GeoRestriction";
import { Provider } from "react-redux";
import store from "./store";

function App(props) {
  const authResult = new URLSearchParams(window.location.search);
  const afftoken = authResult.get("btag");
  React.useEffect(() => {
    if (afftoken) {
      ls.set("afftoken", afftoken);
    }
  }, [afftoken]);

  return (
    <Provider store={store}>
      <AppProvider>
        <GeoRestriction>
          <SecurityPopup>
            <AuthGuard {...props}></AuthGuard>
          </SecurityPopup>
        </GeoRestriction>
      </AppProvider>
    </Provider>
  );
}

export default App;
