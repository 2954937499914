import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import moment from "moment";
import api from "services/api";
import moneyFormatter from "services/moneyFormatter";

export default function Winner(props) {
  const socket = props.socket;
  const [winnerTab, setWinnerTab] = useState("recent");

  const dummyWinners = [
    {
      game: {
        id: 4,
        game_id: "pragmaticexternal:GatesOfOlympus1",
        slug: "gatesofolympus1",
        game_name: "Gates Of Olympus",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/pragmaticexternal/GatesOfOlympus1.png",
        game_name_slugged: "Gates_Of_Olympus",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: false,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "",
        player_surname: "",
        identity: "1",
        player_nickname: "blurryalan",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 0.25,
      win_amount_float: 6.44,
      bet_amount: "0.25",
      win_amount: "6.44",
      profit_float: 6.19,
      profit: "6.19",
      record_date: "2024-07-27 02:36:26",
      type: "cash",
      tr_id: 28472404,
    },
    {
      game: {
        id: 30630,
        game_id: "yggdrasil:777VoltGigaBlox",
        slug: "777-volt-gigablox",
        game_name: "777 Volt GigaBlox",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/yggdrasil/777VoltGigaBlox.png",
        game_name_slugged: "777_Volt_GigaBlox",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: true,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "Omar",
        player_surname: "Talidi",
        identity: "4",
        player_nickname: "Ohmzlaw",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 1,
      win_amount_float: 1,
      bet_amount: "1.00",
      win_amount: "1.00",
      profit_float: 0,
      profit: "0.00",
      record_date: "2024-07-27 02:36:25",
      type: "bonus",
      tr_id: 28472403,
    },
    {
      game: {
        id: 30630,
        game_id: "yggdrasil:777VoltGigaBlox",
        slug: "777-volt-gigablox",
        game_name: "777 Volt GigaBlox",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/yggdrasil/777VoltGigaBlox.png",
        game_name_slugged: "777_Volt_GigaBlox",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: true,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "Omar",
        player_surname: "Talidi",
        identity: "4",
        player_nickname: "Ohmzlaw",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 1,
      win_amount_float: 0.6,
      bet_amount: "1.00",
      win_amount: "0.60",
      profit_float: -0.4,
      profit: "-0.40",
      record_date: "2024-07-27 02:36:16",
      type: "cash",
      tr_id: 28472400,
    },
    {
      game: {
        id: 30630,
        game_id: "yggdrasil:777VoltGigaBlox",
        slug: "777-volt-gigablox",
        game_name: "777 Volt GigaBlox",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/yggdrasil/777VoltGigaBlox.png",
        game_name_slugged: "777_Volt_GigaBlox",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: true,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "Omar",
        player_surname: "Talidi",
        identity: "4",
        player_nickname: "Ohmzlaw",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 1,
      win_amount_float: 7,
      bet_amount: "1.00",
      win_amount: "7.00",
      profit_float: 6,
      profit: "6.00",
      record_date: "2024-07-27 02:35:39",
      type: "bonus",
      tr_id: 28472387,
    },
    {
      game: {
        id: 30630,
        game_id: "yggdrasil:777VoltGigaBlox",
        slug: "777-volt-gigablox",
        game_name: "777 Volt GigaBlox",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/yggdrasil/777VoltGigaBlox.png",
        game_name_slugged: "777_Volt_GigaBlox",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: true,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "Omar",
        player_surname: "Talidi",
        identity: "4",
        player_nickname: "Ohmzlaw",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 1,
      win_amount_float: 0.8,
      bet_amount: "1.00",
      win_amount: "0.80",
      profit_float: -0.19999999999999996,
      profit: "-0.20",
      record_date: "2024-07-27 02:35:00",
      type: "cash",
      tr_id: 28472375,
    },
    {
      game: {
        id: 4,
        game_id: "pragmaticexternal:GatesOfOlympus1",
        slug: "gatesofolympus1",
        game_name: "Gates Of Olympus",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/pragmaticexternal/GatesOfOlympus1.png",
        game_name_slugged: "Gates_Of_Olympus",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: false,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "",
        player_surname: "",
        identity: "1",
        player_nickname: "blurryalan",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 0.25,
      win_amount_float: 0.43,
      bet_amount: "0.25",
      win_amount: "0.43",
      profit_float: 0.18,
      profit: "0.18",
      record_date: "2024-07-27 02:34:57",
      type: "cash",
      tr_id: 28472372,
    },
    {
      game: {
        id: 30630,
        game_id: "yggdrasil:777VoltGigaBlox",
        slug: "777-volt-gigablox",
        game_name: "777 Volt GigaBlox",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/yggdrasil/777VoltGigaBlox.png",
        game_name_slugged: "777_Volt_GigaBlox",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: true,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "Omar",
        player_surname: "Talidi",
        identity: "4",
        player_nickname: "Ohmzlaw",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 1,
      win_amount_float: 0.2,
      bet_amount: "1.00",
      win_amount: "0.20",
      profit_float: -0.8,
      profit: "-0.80",
      record_date: "2024-07-27 02:34:51",
      type: "bonus",
      tr_id: 28472369,
    },
    {
      game: {
        id: 4,
        game_id: "pragmaticexternal:GatesOfOlympus1",
        slug: "gatesofolympus1",
        game_name: "Gates Of Olympus",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/pragmaticexternal/GatesOfOlympus1.png",
        game_name_slugged: "Gates_Of_Olympus",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: false,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "",
        player_surname: "",
        identity: "1",
        player_nickname: "blurryalan",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 0.25,
      win_amount_float: 0.76,
      bet_amount: "0.25",
      win_amount: "0.76",
      profit_float: 0.51,
      profit: "0.51",
      record_date: "2024-07-27 02:34:49",
      type: "bonus",
      tr_id: 28472368,
    },
    {
      game: {
        id: 4,
        game_id: "pragmaticexternal:GatesOfOlympus1",
        slug: "gatesofolympus1",
        game_name: "Gates Of Olympus",
        game_snapshot_url:
          "https://cdn.softswiss.net/i/s3/pragmaticexternal/GatesOfOlympus1.png",
        game_name_slugged: "Gates_Of_Olympus",
        livegame: false,
        jackpot_amount: 0,
        provider_logo: "",
        is_new_category: false,
        is_favorite_game: false,
      },
      player: {
        status: "1",
        player_name: "",
        player_surname: "",
        identity: "1",
        player_nickname: "blurryalan",
        is_anonymous: "0",
        player_currency: "USD",
      },
      currency: "$",
      bet_amount_float: 0.25,
      win_amount_float: 0.28,
      bet_amount: "0.25",
      win_amount: "0.28",
      profit_float: 0.030000000000000027,
      profit: "0.03",
      record_date: "2024-07-27 02:34:40",
      type: "cash",
      tr_id: 28472360,
    },
  ];

  // const [recentWinners, setRecentWinners] = useState([]); //Enable when site has real data.
  const [recentWinners, setRecentWinners] = useState(dummyWinners);

  const getRecentWinners = () => {
    return; //Enable when site has real data.
    setRecentWinners((prevRecentWinners) => {
      var maxId = null;
      if (prevRecentWinners.length) {
        maxId = Math.max(...prevRecentWinners.map((item, index) => item.tr_id));
      }
      api
        .get("/games/recent-winners", { limit: 9, newerThan: maxId })
        .then((response) => {
          setRecentWinners(prevRecentWinners.concat(response.data.data));
        });
      return prevRecentWinners;
    });
  };

  const [topWinners, setTopWinners] = useState([]);
  const getTopWinners = () => {
    setTopWinners((prevTopWinners) => {
      var maxId = null;
      if (prevTopWinners.length) {
        maxId = Math.max(...setTopWinners.map((item, index) => item.tr_id));
      }
      api
        .get("/games/top-winners", { limit: 9, newerThan: maxId })
        .then((response) => {
          setTopWinners(prevTopWinners.concat(response.data.data));
        });
      return prevTopWinners;
    });
  };

  const checkIframe = () => {
    if (window.parent.parent.location.href != window.location.href) {
      window.parent.location.href = window.location.href;
    } else {
    }
  };

  useEffect(() => {
    getTopWinners();
    getRecentWinners();
    checkIframe();
  }, []);

  const [moveWinnerList, setMoveWinnerList] = useState(false);

  // useEffect( () => {
  //   var timeout;
  //   setMoveWinnerList(false);
  //   timeout = setInterval(() => {
  //     setMoveWinnerList((prevMoveWinnerList) => {
  //       console.log("moveWinnerList", prevMoveWinnerList);
  //       return !prevMoveWinnerList;
  //     });
  //   }, 2500);

  //   return () => {
  //     clearInterval(timeout);
  //   };
  // }, []);

  const moveLastToFirst = () => {
    setRecentWinners((prevRecentWinners) => {
      if (prevRecentWinners.length > 0) {
        const newArray = [...prevRecentWinners];

        // Remove the last element from the array
        const lastElement = newArray.pop();
        // const lastElement = newArray[newArray.length - 1];

        lastElement.index = lastElement.tr_id.toString() + moment.now();

        // Add the last element to the beginning of the array
        newArray.unshift(lastElement);

        // Log the modified array for debugging
        // console.log("newArray", newArray);

        // Return the modified array
        return newArray;
      }

      // If the array is empty, return the current state
      return prevRecentWinners;
    });

    setMoveWinnerList((prevMoveWinnerList) => {
      // console.log("moveWinnerList", prevMoveWinnerList);
      setMoveWinnerList(!prevMoveWinnerList);
      setTimeout(() => {
        setMoveWinnerList(prevMoveWinnerList);
      }, 1000);
      return prevMoveWinnerList;
    });
  };

  useEffect(() => {
    var rounds = 0;
    const intervalId = setInterval(() => {
      rounds = ++rounds % 5;
      moveLastToFirst();
      if (!rounds) {
        getRecentWinners();
      }
    }, 2000); // Adjust the interval time as needed
    window.addEventListener("click", () => moveLastToFirst());
    // Clear the interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  const [tab, setTab] = useState("Latest Winner");

  return (
    <div className="winners_block">
      {/* <div className="headline_text">Latest sweeplay winners!</div> */}
      <div className="tabs">
        <Link
          hreflang={Translator.getSelectedLanguage().code}
          className={
            "commonBtn purpleBtn " + (tab == "Latest Winner" ? "active" : "")
          }
        >
          {__("Latest winners!")}
        </Link>{" "}
        <Link
          hreflang={Translator.getSelectedLanguage().code}
          className={
            "commonBtn purpleBtn " + (tab == "BIG Winner" ? "active" : "")
          }
        >
          {__("BIG Winner")}
        </Link>
      </div>
      <div className="winners ">
        {tab == "Latest Winner" && (
          <>
            <div className="winners-slider">
              {recentWinners.map((item, index) => (
                <div
                  key={item.index}
                  className={
                    "item " + (item.type == "bonus" ? "bonus" : "cash")
                  }
                >
                  <div className="winner-image">
                    <a>
                      <img src={item.game.game_snapshot_url} />
                    </a>
                  </div>
                  <div className="winner-content">
                    <span className="win-amount winner_line">
                      <i>
                        {__("Won")}{" "}
                        <span className="win_amount">
                          {(item.type == "bonus" ? "GC" : "SC") +
                            " " +
                            item.win_amount}
                        </span>{" "}
                        {__("on")}{" "}
                      </i>
                    </span>
                    <span className="win-game winner_line">
                      <a href="#">{item.game.game_name}</a>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
        {tab == "BIG Winner" && (
          <>
            <div className="winners-slider">
              {recentWinners.map((item, index) => (
                <div
                  key={item.index}
                  className={
                    "item " + (item.type == "bonus" ? "bonus" : "cash")
                  }
                >
                  <div className="winner-image">
                    <a>
                      <img src={item.game.game_snapshot_url} />
                    </a>
                  </div>
                  <div className="winner-content">
                    <span className="win-amount winner_line">
                      <i>
                        {__("Won")}{" "}
                        <span className="win_amount">
                          {(item.type == "bonus" ? "GC" : "SC") +
                            " " +
                            item.win_amount}
                        </span>{" "}
                        {__("on")}{" "}
                      </i>
                    </span>
                    <span className="win-game winner_line">
                      <a href="#">{item.game.game_name}</a>
                    </span>
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    </div>
  );
}
