import { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function ScrollToTop() {
  const location = useLocation();

  useEffect(() => {
    // Scroll to top when the route changes
    window.scrollTo(0, 0);
    document.getElementById("mainContent").scrollTo(0, 0);
    // console.log("Location changed:", location.pathname);
  }, [location]); // Trigger effect when location changes

  return null; // This component does not render anything
}
