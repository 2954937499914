import React, { useState, useEffect } from "react";
import { Accordion } from "react-bootstrap";
import { Redirect, Route, Link } from "react-router-dom";

const TransactionMobileDataView = (props) => {
  const [transactions, setTransactions] = useState({});
  useEffect(() => {
    setTransactions(props.dataSet);
  }, [props]);

  return (
    <div className="transactions mobile_tr_view">
      {props.transactionType == "Deposits" && (
        <>
          {transactions.length > 0 && (
            <Accordion>
              {transactions.map((value, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    <div className="mobile_tr_heading">
                      <div className="single_data">
                        <label>Date</label>
                        <div className="tr_data">{value.action_date}</div>
                      </div>
                      <div className="single_data">
                        <label>Amount</label>
                        <div className="tr_data_text">{value.amount}</div>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="tr_data">
                      <div className="single_data">
                        <label>Package</label>
                        <div className="tr_data_text">
                          SC:{value?.sweepcoinPurchase?.sweep_coins}
                          <br />
                          GC: {value?.sweepcoinPurchase?.gold_coins}
                        </div>
                      </div>
                      <div className="single_data">
                        <label>Payment Method</label>
                        <div className="tr_data_text">{value.method}</div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </>
      )}
      {props.transactionType == "Withdrawals" && (
        <>
          {transactions.length > 0 && (
            <Accordion>
              {transactions.map((value, index) => (
                <Accordion.Item eventKey={index}>
                  <Accordion.Header>
                    <div className="mobile_tr_heading">
                      <div className="single_data">
                        <label>Date</label>
                        <div className="tr_data">{value.action_date}</div>
                      </div>
                      <div className="single_data">
                        <label>SC amount redeemed</label>
                        <div className="tr_data_text">{value.amount}</div>
                      </div>
                    </div>
                  </Accordion.Header>
                  <Accordion.Body>
                    <div className="tr_data">
                      <div className="single_data">
                        <label>Redeem Method</label>
                        <div className="tr_data_text">{value.method}</div>
                      </div>
                    </div>
                  </Accordion.Body>
                </Accordion.Item>
              ))}
            </Accordion>
          )}
        </>
      )}
    </div>
  );
};

export default TransactionMobileDataView;
