import React, { useState, useEffect } from "react";
// import { Redirect, Route, Link } from "react-router-dom";
import { __ } from "services/Translator";

const GameImage = ({ src }) => {
  const [gameSnapshotUrl, setGameSnapshotUrl] = useState(src);
  useEffect(() => {
    setGameSnapshotUrl(src);
  }, [src]);
  const setDefaultImage = () => {
    setGameSnapshotUrl(
      require("assets/sweeplay/images/games/default.png")
    );
  };

  return <img src={gameSnapshotUrl} onError={setDefaultImage} />;
};

export default GameImage;
