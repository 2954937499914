import React, { useState, useEffect, useCallback } from "react";
import { __ } from "services/Translator";
import ProgressBar from "react-bootstrap/ProgressBar";
import WheelOfFortune from "elements/WheelOfFortune";
import { Modal, Button, Alert } from "react-bootstrap";
import api from "services/api";

export default function RewardSystem(props) {
  const [wheelWagerStatus, setWheelWagerStatus] = useState({});
  const [fortuneWheel, setFortuneWheel] = useState(null); //THe database object of the wheel data

  const [showWheel, setShowWheel] = useState(false); //The wheel object
  const closeAllModals = () => {
    setShowWheel(false);
    getWheelOfFortune();
  };

  const getWheelOfFortune = async () => {
    var response = await api.get("/rewards/fortune-wheel/status");
    if (response.status === 200) {
      setFortuneWheel(response.data.wheel);
      setWheelWagerStatus(response.data.wagerStatus);
    }
  };

  useEffect(() => {
    getWheelOfFortune();
  }, []);

  return (
    <>
      {!!fortuneWheel && (
        <div className="fortune_status">
          <div className="title">{__("Available fortune wheels")}</div>
          <div className="fortune_details">
            <div className="fortune_icon">
              <img src={require("assets/sweeplay/images/common/fortune.webp")} />
            </div>
            <div className="fortune_text">
              <div className="subtitle">{__("Free") + " " + fortuneWheel?.fortune_type?.title}</div>
              <div className="available">{__("Available to spin")}</div>
            </div>
          </div>
          <div className="fortune_button">
            <Button onClick={() => setShowWheel(true)} className={"commonBtn orengeRedBtn noBorder"}>
              {__("Spin Now")}
            </Button>
          </div>
        </div>
      )}
      <Modal className="fortune_wheel_modal" show={!!fortuneWheel && showWheel} onHide={closeAllModals}>
        <Modal.Body className="login_modal forgot_pass">
          <div onClick={closeAllModals} className="modal_close_reg small">
            <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M6.26872 8.99995L1.05492 3.59305C0.775612 3.3034 0.589405 2.86892 0.589405 2.43443C0.589405 1.99995 0.775612 1.56547 1.05492 1.27581C1.66009 0.648226 2.68423 0.648226 3.2894 1.27581L8.5032 6.68271L13.717 1.27581C14.3222 0.648226 15.3463 0.648226 15.9515 1.27581C16.2308 1.56547 16.417 1.99995 16.417 2.43443C16.417 2.86892 16.2308 3.3034 15.9515 3.59305L10.7377 8.99995L15.9515 14.4068C16.2308 14.6965 16.417 15.131 16.417 15.5655C16.417 15.9999 16.2308 16.4344 15.9515 16.7241C15.6722 17.0137 15.2532 17.2068 14.8342 17.2068C14.4153 17.2068 13.9963 17.0137 13.717 16.7241L8.5032 11.3172L3.2894 16.7241C2.68423 17.3517 1.66009 17.3517 1.05492 16.7241C0.775612 16.4344 0.589405 15.9999 0.589405 15.5655C0.589405 15.131 0.775612 14.6965 1.05492 14.4068L6.26872 8.99995Z"
                fill="#10294F"
              />
            </svg>
          </div>
          <div className="reward_system">
            {/* Wheel of Fortune Block */}
            <section className="lucky_wheel">
              <WheelOfFortune
                pendingWheelStatus={wheelWagerStatus}
                wheel={fortuneWheel}
                getWheelOfFortune={getWheelOfFortune}
                usePropsWheel={true}
                {...props}
              />

              <div className="wheel_text_area">
                <div className="headline_colored">{__("Sweeplay Wheel Of Fortune")}</div>
                <div className="dsc">{__("Spin the wheel each day for a chance to win.")}</div>
              </div>
            </section>
          </div>{" "}
        </Modal.Body>
      </Modal>
    </>
  );
}
