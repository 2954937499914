import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import api from "services/api";
// import auth from "services/auth";
// import ls from "services/ls";
import config from "configs/config";

import Translator, { __ } from "services/Translator";
// import MyTickets from "elements/MyTickets";

import emailValidate from "../services/emailValidate";
import Faqs from "../components/Faqs";

export default function LiveChat(props) {
  // const __ = (text) => text;
  const location = useLocation();
  const isAuthenticated = props.isAuthenticated;
  const userDetails = props.userDetails;
  const supportSidebarOpen = props.supportSidebarOpen;
  const setSupportSidebarOpen = props.setSupportSidebarOpen;
  const selectedLanguage = Translator.getSelectedLanguage();

  const [form, setForm] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const updateForm = (value, field) => {
    if (isAuthenticated && (field === "email" || field === "name")) {
      return false;
    }
    setForm({ ...form, [field]: value });
  };

  useEffect(() => {
    if (isAuthenticated) {
      setForm({
        ...form,
        email: userDetails.email,
        name: userDetails.first_name
          ? userDetails.first_name + " " + userDetails.last_name
          : userDetails.nick_name,
      });
    }
  }, [userDetails]);

  const [launchUrl, setLaunchUrl] = useState(config.liveChatUrl);
  // const [launchUrl, setLaunchUrl] = useState("https://google.com");

  useEffect(() => {
    setLaunchUrl(config.liveChatUrl + "&lang=" + selectedLanguage.code);
  }, [selectedLanguage]);

  const onWebViewLoadedIframe = () => {
    props.setSpinner(false);
  };

  const toggleSupportSidebar = () => {
    setSupportSidebarOpen(!supportSidebarOpen);
    props.setShowRightSidebar(false);
  };

  const [activeTab, setActiveTab] = useState(1);

  return (
    <>
      {/* {supportSidebarOpen && (
        <div className="invisible_overlay" onClick={toggleSupportSidebar}></div>
      )} */}
      {!props.isMobileDevice && (
        <a
          onClick={toggleSupportSidebar}
          href="#"
          className="floatingSupportBtn"
        >
          <img
            alt=""
            style={{ width: "40px" }}
            src={
              require("assets/sweeplay/images/support/livechat-headphone.svg")
                .default
            }
          />
        </a>
      )}

      <div
        className={
          "support_panel support_panel_area floating " +
          (supportSidebarOpen ? "open " : " ")
        }
        id="autoprocessed"
      >
        <a
          onClick={toggleSupportSidebar}
          className="close_icon"
          href="javascript:"
        >
          <i className="fal fa-times"></i>
        </a>

        {/* <div className="panel_header">
          <h3>{__("Support")}</h3>
        </div> */}

        <div className="support_sidebar_content faqs_area">
          <div
            className={
              "liveChatContainer " + (activeTab == 1 ? "d-block" : "d-none")
            }
          >
            <iframe
              className="support_chat "
              src={launchUrl}
              onLoad={onWebViewLoadedIframe}
            />
          </div>
        </div>

        {/*<div onClick={toggleSupportSidebar} className="collapse-support">
          {__("Collapse")}
        </div>*/}
      </div>
    </>
  );
}
