import React, { useState, useEffect } from "react";

import { __ } from "services/Translator";
import api from "services/api";
import Swal from "sweetalert2";
import { usePlaidLink } from "react-plaid-link";
import { useSelector } from "react-redux";
import Pagination from "elements/Pagination";
import { Accordion, Modal } from "react-bootstrap";

export default function Redeem(props) {
  const userBalance = useSelector((state) => state.userBalance);

  const [payoutMethods, setPayoutMethods] = useState([]);
  const [payoutMethod, setPayoutMethod] = useState(null);

  const [redeemForm, setRedeemForm] = useState({
    amount: "",
    email: "",
    crypto: "BTC",
  });
  const [errors, setErrors] = useState({});

  const [bankForm, setBankForm] = useState({
    bankAccID: "",
    name: "", //Account name
  });

  const [bankErrors, setBankErrors] = useState({
    bankAccID: "",
  });

  const getPayoutMethods = async () => {
    if (redeemForm.amount) {
      var response = await api.post("/withdraw/methods", {
        amount: redeemForm.amount,
      });

      if (response.status === 200) {
        setPayoutMethods(response.data.methods);
        if (
          !response.data.methods.filter(
            (method) => method?.method_id === payoutMethod?.method_id
          ).length
        ) {
          setPayoutMethod(null);
        }
      }
    }
  };

  useEffect(() => {
    getPayoutMethods();
  }, [redeemForm.amount]);

  //Plaid methods start here

  const [linkToken, setLinkToken] = useState(null);

  const createLinkToken = async () => {
    var tokenResponse = await api.get("/dwolla/plaid/link/create");
    if (tokenResponse.status === 200) {
      var linkToken = tokenResponse.data.link_token;
      setLinkToken(linkToken);
      console.log(`Created Link Token: ${linkToken}`);
    }
  };

  useEffect(() => {
    if (createLinkToken && !linkToken) {
      createLinkToken().catch((err) => console.error(err));
    }
  }, []);

  const handlePlaidLinkSuccess = async (publicToken, metadata) => {
    console.log("publicToken", publicToken);
    console.log("metadata", metadata);

    var response = await api.post("/dwolla/plaid/funding-source/create", {
      publicToken: publicToken,
      accounts: metadata.accounts,
    });

    console.log(response);
    await getPayoutMethods();
  };

  const { open: openPlaidLink, ready: isPlaidLinkReady } = usePlaidLink({
    onSuccess: handlePlaidLinkSuccess,
    token: linkToken,
  });

  //Plaid methods ends here

  const [redeemablAmount, setRedeemableAmount] = useState(0);
  const getRedeemableAmount = async () => {
    var response = await api.get("/get-redeemable-amount");
    if (response.status === 200) {
      setRedeemableAmount(response.data);
    }
  };

  useEffect(() => {
    getRedeemableAmount();
  }, []);

  const validateBankForm = () => {
    var valid = true;
    var errorsTmp = {};

    if (!bankForm.bankAccID) {
      errorsTmp = {
        ...errorsTmp,
        bankAccount: __("Please select a bank or add a new one"),
      };
      valid = false;
    }

    setBankErrors(errorsTmp);
    return valid;
  };

  const submitRedeemRequest = async (ev) => {
    ev.preventDefault();
    props.updateSpinner(true);
    setErrors({});
    var valid = true;
    var errorsTmp = {};

    if (!parseFloat(redeemForm.amount)) {
      errorsTmp.amount = "Please enter the payout amount";
      valid = false;
    } else {
      if (!payoutMethod) {
        errorsTmp.method = "Please select a method";
        valid = false;
      } else {
        if (payoutMethod.method_id == "DIRECT:WireTransfer") {
          if (!redeemForm.email) {
            valid = false;
            errorsTmp.email = "Please enter your email";
          }
        } else if (payoutMethod.method_id == "Dwolla") {
          valid = validateBankForm();
          redeemForm.bankForm = bankForm;
        }
      }
    }
    setErrors(errorsTmp);
    if (!valid) {
      props.updateSpinner(false);
      return;
    }

    redeemForm.method = payoutMethod.method_id;
    var response = await api.post("/withdraw", redeemForm);
    props.updateSpinner(false);
    if (response.status === 200) {
      setRedeemForm({ amount: "", email: "", crypto: "BTC" });
      setPayoutMethods([]);
      setPayoutMethod(null);
      setBankForm({ bankAccID: "" });

      Swal.fire({
        title: "Success!",
        text: response.data.message,
        icon: "success",
      });
    } else {
      Swal.fire({
        title: "warning!",
        text: response.data.message,
        icon: "warning",
      });
    }
  };

  const updateRedeemForm = (value, field) => {
    setRedeemForm({ ...redeemForm, [field]: value });
  };

  //Transaction history
  const defaultForm = {
    historyFor: "Withdrawals",
    startDate: null,
    endDate: null,
    page: 1,
    triggerSearch: true,
  };
  const [results, setResults] = useState([]);
  const [form, setForm] = useState(defaultForm);
  const [pagination, setPagination] = useState([]);
  const updateForm = (value, field) => {
    setForm({ ...form, [field]: value });
  };
  const getResults = async () => {
    var response = await api.post("/history/transactions", form);

    if (response.status === 200) {
      setResults(response.data.data);
      setPagination(response.data.paginationData);
    } else {
      props.showAlert(null, response.data.message, true);
    }
    updateForm(false, "triggerSearch"); //Ready for next trigger
  };

  useEffect(() => {
    getResults();
  }, []);

  const onPageChange = (page) => {
    setForm({ ...form, page: page, triggerSearch: true });
  };
  const [cancelWithdrawalId, setCancelWithdrawalId] = useState(null);
  const closeAllModals = () => {
    setCancelWithdrawalId(null);
  };

  const cancelWithdrawal = async () => {
    if (cancelWithdrawalId) {
      props.setSpinner(true);
      var response = await api.post("/withdraw/cancel", {
        id: cancelWithdrawalId,
      });
      props.setSpinner(false);
      closeAllModals();
      if (response.status === 200) {
        getResults();
      } else {
        props.showAlert(null, response.data.message, true);
      }
    }
  };
  return (
    <>
      <div className="container redeemArea">
        <div className="row">
          <div className="col-md-6 offset-md-3">
            <div className="access_control_area accordion">
              <div className="chimp-accordion accordion-item">
                <h2 class="accordion-header">
                  <button
                    type="button"
                    aria-expanded="false"
                    class="accordion-button"
                  >
                    {__("Request a redeem")}
                  </button>
                </h2>
                <div className="accordion-collapse">
                  <div className="accordion-body">
                    <div className="myaccount_input_area">
                      <div className="row">
                        <form onSubmit={submitRedeemRequest}>
                          <p>
                            {__(
                              "Sweepstake Coins must be played at least once before being eligible for redemption as a cash prize."
                            )}{" "}
                            <br />
                            {/* {__("Sweepstake Coins can be redeemed for $1 USD.")} */}
                            {__(
                              "Minimum amount for redeeming Sweepstake Coins: 100"
                            )}
                            <br />
                            <br />
                            <strong>
                              {__("Your Sweepstake Coin balance: ")}
                            </strong>{" "}
                            SC {userBalance.cash_balance}
                            <br />
                            <strong>
                              {__("Available to redeem: ")}
                            </strong> SC {redeemablAmount}
                            <br />
                            <strong>{__("Unplayed balance: ")}</strong> SC{" "}
                            {userBalance.cash_balance - redeemablAmount}
                            <br />
                          </p>

                          <div className="user_blocks redeem-input">
                            <div className="single_block twoItems">
                              <div className="item input_item">
                                <div className="input_label">
                                  {__("Redeem Amount")}
                                  <span className="requried_icon"> *</span>
                                </div>
                                <input
                                  type="number"
                                  name="amount"
                                  placeholder={__("Amount")}
                                  max={redeemablAmount}
                                  value={redeemForm.amount}
                                  onChange={(ev) =>
                                    updateRedeemForm(ev.target.value, "amount")
                                  }
                                  className={
                                    "form-control input_box name_value" +
                                    (errors.amount ? "error" : "")
                                  }
                                />
                                <div class="errorMsg">{errors.amount}</div>
                              </div>
                              {payoutMethod?.method_id ==
                                "DIRECT:WireTransfer" && (
                                <div className="item">
                                  <div className="input_label">
                                    {__("Email")}
                                    <span className="requried_icon"> *</span>
                                  </div>

                                  <input
                                    type="email"
                                    value={redeemForm.email}
                                    onChange={(ev) =>
                                      updateRedeemForm(ev.target.value, "email")
                                    }
                                    placeholder={__("Email")}
                                    className={
                                      "form-control input_box " +
                                      (errors.email ? "error" : "")
                                    }
                                  />
                                  <div class="errorMsg">{errors.email}</div>
                                </div>
                              )}
                              {payoutMethod?.method_id == "TRIPLEA" && (
                                <>
                                  <div className="item">
                                    <div className="input_label">
                                      {__("Crypto")}
                                      <span className="requried_icon"> *</span>
                                    </div>

                                    <select
                                      value={redeemForm.crypto}
                                      onChange={(ev) =>
                                        updateRedeemForm(
                                          ev.target.value,
                                          "crypto"
                                        )
                                      }
                                      className={
                                        "form-control input_box " +
                                        (errors.crypto ? "error" : "")
                                      }
                                    >
                                      <option value="BTC">BTC</option>
                                      <option value="ETH">ETH</option>
                                      <option value="LNBC">LNBC</option>
                                      <option value="USDC">USDC</option>
                                      <option value="USDC_TRC20">
                                        USDC_TRC20
                                      </option>
                                      <option value="USDT">USDT</option>
                                      <option value="USDT_TRC20">
                                        USDT_TRC20
                                      </option>
                                    </select>
                                    <div class="errorMsg">{errors.crypto}</div>
                                  </div>

                                  <div className="item">
                                    <div className="input_label">
                                      {__("Wallet Address")}
                                      <span className="requried_icon"> *</span>
                                    </div>

                                    <input
                                      type="text"
                                      value={redeemForm.accNumber}
                                      onChange={(ev) =>
                                        updateRedeemForm(
                                          ev.target.value,
                                          "accNumber"
                                        )
                                      }
                                      placeholder={__("Wallet Address")}
                                      className={
                                        "form-control input_box " +
                                        (errors.accNumber ? "error" : "")
                                      }
                                    />
                                    <div class="errorMsg">
                                      {errors.accNumber}
                                    </div>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>

                          <div className="other_method_area redeem-method">
                            <ul>
                              {payoutMethods.map((method, index) => (
                                <li
                                  className={
                                    method?.method_id ===
                                    payoutMethod?.method_id
                                      ? "active"
                                      : ""
                                  }
                                >
                                  <input
                                    id={"all_payout_" + method.id}
                                    onChange={() => {
                                      setPayoutMethod(method);
                                    }}
                                    type="radio"
                                    checked={
                                      method?.method_id ===
                                      payoutMethod?.method_id
                                    }
                                  />
                                  <label htmlFor={"all_payout_" + method.id}>
                                    <img alt="" src={method.logo} />{" "}
                                    {method.method}
                                  </label>
                                </li>
                              ))}
                            </ul>
                            <div class="errorMsg">{errors.method}</div>
                            <div
                              className={
                                "credit_card_from_area " +
                                (payoutMethod?.method_id == "Dwolla"
                                  ? "d-block"
                                  : "d-none")
                              }
                            >
                              {payoutMethod?.fundingSources && (
                                <div className="existing_accounts">
                                  <div className="subheading">
                                    {__("Select a bank account")}
                                    <span className="requried_icon">*</span>
                                  </div>
                                  {payoutMethod?.fundingSources.map(
                                    (item, index) => (
                                      <div className="bank_account_list_item">
                                        <a
                                          className={
                                            "common_button bank_account_name " +
                                            (bankForm.bankAccID ===
                                            item._links.self.href
                                              ? "active"
                                              : "")
                                          }
                                          onClick={() =>
                                            setBankForm({
                                              ...bankForm,
                                              bankAccID: item._links.self.href,
                                            })
                                          }
                                        >
                                          {item.name}
                                        </a>
                                      </div>
                                    )
                                  )}
                                  <div className="bank_account_list_item">
                                    <a
                                      className="common_button bank_account_name"
                                      onClick={() => openPlaidLink()}
                                    >
                                      {__("New account")}
                                    </a>
                                  </div>

                                  <div className="errorMsg">
                                    {bankErrors.bankAccount}
                                  </div>
                                </div>
                              )}
                            </div>

                            <div className="center">
                              <button
                                type="submit"
                                className="commonBtn casinoBtn"
                              >
                                {__("Submit")}
                              </button>
                            </div>
                          </div>
                        </form>
                      </div>
                      {results.length > 0 && (
                        <div className="row">
                          <div className="table_area">
                            <h3>{__("Redeem History")}</h3>
                            <div className="d-none d-sm-block">
                              <div className="heading_row transaction_history">
                                <div className="item">{__("Date")}</div>
                                <div className="item">{__("Amount")}</div>
                                <div className="item">{__("Method")}</div>
                                <div className="item">{__("Status")}</div>
                              </div>

                              {results.length ? (
                                <>
                                  {results.map((item, index) => (
                                    <div
                                      key={index}
                                      className="data_row transaction_history"
                                    >
                                      <div className="item">
                                        {item.action_date}
                                      </div>
                                      <div className="item">
                                        <b>{item.amount}</b>
                                      </div>
                                      <div className="item">{item.method}</div>
                                      <div className="item">
                                        {item.type === "Withdrawal" &&
                                        parseInt(item.status_code) === 0 ? (
                                          <div
                                            style={{
                                              display: "flex",
                                              gridGap: 15,
                                            }}
                                          >
                                            {/* <input
                                            disabled={true}
                                            type="text"
                                            className="form-controll input_box active with_button"
                                            value={item.status}
                                          /> */}
                                            {item.status}
                                            <a
                                              onClick={() =>
                                                setCancelWithdrawalId(item.id)
                                              }
                                              className="commonBtn casinoBtn"
                                            >
                                              {__("Cancel")}
                                            </a>
                                          </div>
                                        ) : (
                                          <>
                                            {/* <input
                                            disabled={true}
                                            type="text"
                                            className="form-controll input_box active"
                                            value={item.status}
                                          /> */}
                                            {item.status}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                  ))}

                                  <div className="pagination">
                                    <Pagination
                                      data={pagination}
                                      onPageChange={onPageChange}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  {/* <NoRecordsFound /> */}
                                  {__("no data found")}
                                </>
                              )}
                            </div>

                            <div className="mobile_accordion d-block d-sm-none">
                              {results.length ? (
                                <>
                                  <Accordion>
                                    {results.map((item, index) => (
                                      <Accordion.Item
                                        eventKey={index}
                                        key={index}
                                      >
                                        <Accordion.Header>
                                          {item.type}
                                        </Accordion.Header>
                                        <Accordion.Body>
                                          <div className="accordion_content">
                                            <div className="input_item">
                                              <div className="name">
                                                {__("Type")}
                                              </div>
                                              <div className="form-control name_value">
                                                {item.type}
                                              </div>
                                            </div>
                                            <div className="input_item">
                                              <div className="name">
                                                {__("Date")}
                                              </div>
                                              <div className="form-control name_value">
                                                {item.action_date}
                                              </div>
                                            </div>
                                            <div className="input_item">
                                              <div className="name">
                                                {__("Amount")}
                                              </div>
                                              <div className="form-control name_value">
                                                {item.amount}
                                              </div>
                                            </div>

                                            <div className="input_item">
                                              <div className="name">
                                                {__("Method")}
                                              </div>
                                              <div className="form-control name_value">
                                                {item.method}
                                              </div>
                                            </div>
                                          </div>
                                        </Accordion.Body>
                                      </Accordion.Item>
                                    ))}
                                  </Accordion>

                                  <br />
                                  <br />
                                  <div className="pagination">
                                    <Pagination
                                      data={pagination}
                                      onPageChange={onPageChange}
                                    />
                                  </div>
                                </>
                              ) : (
                                <>
                                  {/* <NoRecordsFound /> */}
                                  {__("No Data found")}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        className="gamePlayMsgModal"
        show={!!cancelWithdrawalId}
        onHide={closeAllModals}
        centered
      >
        <Modal.Header>
          <h5 className="modal-title">{__("Attention!")}</h5>
        </Modal.Header>
        <Modal.Body className="login_modal forgot_pass">
          <div className="game_message_content">
            {__("Are you sure you wish to cancel your Redeem request?")}
            <br />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="commonBtn casinoBtn" onClick={closeAllModals}>
            {__("No")}
          </button>
          <button className="commonBtn bannerBtn" onClick={cancelWithdrawal}>
            {__("Yes")}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}
