import React from "react";
import api from "services/api";
import auth from "services/auth";
import ls from "services/ls";
import { updateUserBalance } from "stores/balance/userBalance";
import { setIsAuthenticated } from "stores/auth/userAuth";
import { updateLoginBonus } from "stores/bonus/loginBonus";
import { useSelector, useDispatch } from "react-redux";

const AppContext = React.createContext();

function AppProvider({ children }) {
  const dispatch = useDispatch();

  const getUserBalance = async (ev) => {
    var response = await api.get("/get-balance");
    // console.log(response);
    ls.set("conversionRates", response.data.rates);
    dispatch(updateUserBalance(response.data));
  };

  const updateUserAuthStatus = async () => {
    var authenticated = await auth.isAuthenticated();
    dispatch(setIsAuthenticated(!!authenticated));
    if (!authenticated) {
      dispatch(updateLoginBonus({ sc: 0, gc: 0 }));
      ls.set("loginBonusData", { sc: 0, gc: 0 });
    }
  };

  const setLoginBonus = async (bonusData) => {
    if (bonusData) {
      ls.set("loginBonusData", bonusData);
    } else {
      bonusData = await ls.get("loginBonusData");
    }
    dispatch(updateLoginBonus(bonusData));
  };

  return (
    <AppContext.Provider value={{ updateUserAuthStatus, getUserBalance, setLoginBonus }}>
      {children}
    </AppContext.Provider>
  );
}

export default AppContext;
export { AppProvider };
