import React, { useState, useEffect } from "react";
import {
  Redirect,
  Route,
  Link,
  useHistory,
  useLocation,
} from "react-router-dom";
import auth from "../services/auth";
import Spinner from "elements/Spinner";
import AppContext from "../contexts/AppContext";
import api from "../services/api";
import emailValidate from "../services/emailValidate";
import ls from "services/ls";
import Translator, { __ } from "services/Translator";

import validator from "validator";
import swal from "sweetalert2";

import SocialLogin from "/components/SocialLogin";
import { Modal, Button, Alert, Accordion } from "react-bootstrap";

//New structured items
import RegCampaigns from "components/RegCampaigns";
import Logo from "elements/Logo";

const LoginReg = (props) => {
  const location = useLocation();
  const history = useHistory();
  const [spinner, setSpinner] = useState(false);

  const [showLoginModal, setShowLoginModal] = useState(false);
  const openLoginModal = () => {
    setShowLoginModal(true);
    setActiveTab("login");
    // setShowSidebar(false);
  };
  const closeLoginModal = () => {
    setShowLoginModal(false);
  };

  const selectedLanguage = Translator.getSelectedLanguage();

  //SignUp Modal
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const openSignUpModal = () => {
    // setShowSignUpModal(true);
    setActiveTab("Sign up");
    setShowLoginModal(true);
    // setShowSidebar(false);
  };
  const closeSignUpModal = () => {
    setShowSignUpModal(false);
  };

  // tabs
  const [activeTab, setActiveTab] = useState("login");
  const toggleTab = (tabName) => {
    setActiveTab(tabName);
  };

  // recoverModal
  const [recoverPssModal, setRecoverPssModal] = useState(false);
  const openRecoverPssModal = () => {
    setRecoverPssModal(true);
    setShowLoginModal(false);
    setSentForgotPassMail(false);
  };
  const closeRecoverPssModal = () => {
    setRecoverPssModal(false);
  };

  const [alertMsg, setAlertMsg] = useState({
    message: "Successfully added",
    variant: "success",
    showAlert: false,
  });

  const { updateUserAuthStatus, setLoginBonus } = React.useContext(AppContext);
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [userType, setUserType] = useState(auth.getUserType);
  const [modalForgotPassword, setModalForgotPassword] = useState(
    props.modalForgotPassCall
  );

  const [email, setEmail] = useState("");
  const [pass, setPass] = useState("");

  const [action, setAction] = useState(props.action ? props.action : "signup"); //signup, login depending on account exsitance
  const [selectedMenu, setSelectedMenu] = useState(props.selectedMenu);

  const [modalLogin, setModalLogin] = useState(props.modalLoginCall);
  const [modalLoginCall, setModalLoginCall] = useState(false);

  const [initModalLoginProps, setInitModalLoginProps] = useState({
    loging_modal: false,
    forgot_pass_modal: false,
  });

  const [loginData, setLoginData] = useState({
    loging_modal: false,
    forgot_pass_modal: false,
  });
  const switchToLogin = () => {
    closeAllModals();
    // setModalLogin(true);
    setShowLoginModal(true);
  };

  const switchToForgotPassword = () => {
    closeAllModals();
    setModalForgotPassword(true);
    setSentForgotPassMail(false);
  };

  const closeAllModals = () => {
    // setModalLogin(false);
    setShowLoginModal(true);
    setModalForgotPassword(false);
  };

  useEffect(() => {
    // setModalLogin(props.modalLoginPropsData);
    // alert(props.modalLoginPropsData)
    if (props.modalLoginPropsData == true) {
      setShowLoginModal(props.modalLoginPropsData);
      setActiveTab("login");
      setModalForgotPassword(props.modalForgotPasswordPropsData);
    }

    console.log("props.modalSignUpPropsData", props.modalSignUpPropsData);
    if (props.modalSignUpPropsData == true) {
      setShowLoginModal(props.modalSignUpPropsData);
      setActiveTab("Sign up");
      setModalForgotPassword(props.modalForgotPasswordPropsData);
    }
  }, [props]);

  useEffect(() => {
    if (location.pathname === "/login" || location.pathname === "/register") {
      setShowLoginModal(true);
      setModalForgotPassword(false);
      if (location.pathname === "/login") {
        setActiveTab("login");
      } else if (location.pathname === "/register") {
        setActiveTab("Sign up");
      }
    } else if (location.pathname === "/recover-password") {
      openRecoverPssModal();
    }
  }, [location.pathname]);

  const updateEmail = (ev) => {
    setEmail(ev.target.value);
  };
  const updatePass = (ev) => {
    setPass(ev.target.value);
  };

  const [loginForm, setLoginForm] = useState({
    email: "",
    password: "",
    forceLogin: true,
  });

  const [errors, setErrors] = useState({});
  const [mainErrorMgs, setMainErrorMgs] = useState("");

  const updateForm = (field, value) => {
    setLoginForm({ ...loginForm, [field]: value });
  };

  const updateInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    updateForm(field, value);
    checkInput(ev);
  };

  const updateSelect = (a, b) => {
    var field = b.name;
    var value = a.value;
    updateForm(field, value);
  };

  const validateForm = () => {
    var valid = true;
    // return valid;
    var errorsTmp = {};

    // if (!emailValidate(loginForm.email)) {
    //   errorsTmp = { ...errorsTmp, email: __("Please enter a valid email address") };
    //   valid = false;
    // }

    if (loginForm.email.length < 2 || loginForm.email.length > 250) {
      errorsTmp = { ...errorsTmp, email: __("Please enter your email") };
      valid = false;
    }

    if (loginForm.password.length < 2 || loginForm.password.length > 250) {
      errorsTmp = { ...errorsTmp, password: __("Please enter your password") };
      valid = false;
    }

    setErrors(errorsTmp);

    return valid;
  };

  const [userDetails, setUserDetails] = useState(props.userDetails);

  const login = async (ev) => {
    ev.preventDefault();
    var valid = validateForm();
    setMainErrorMgs("");
    if (valid) {
      setSpinner(true);
      var response = await api.post("/login", loginForm);
      console.log("loginData", response);
      setSpinner(false);
      if (response.status == 200) {
        await auth.login(response.data.user);
        updateUserAuthStatus(response.data.user);
        setLoginBonus(response.data.daily_sc);
        setUserDetails(auth.getUser());
        // setRegistrationSuccessModal(true);
        setShowLoginModal(false);
        // window.location.href = "/games/all";
        history.push("/games/all");

        // if (response.data.daily_sc?.sc > 0) {
        //   Swal.fire({
        //     title: "Daily Bonus!",
        //     html:
        //       "<b>Congratulations!!</b><br /><p>You are eligible for SC" +
        //       response.data.daily_sc?.sc +
        //       " and GC" +
        //       response.data.daily_sc?.gc +
        //       ".<br />Claim your bonus now.",
        //     icon: "success",
        //     showCancelButton: true,
        //     focusConfirm: false,
        //     confirmButtonText: '<i class="fa fa-thumbs-up"></i> Claim Bonus!',
        //     confirmButtonAriaLabel: "Claim Bonus!",
        //     cancelButtonText: "Skip",
        //     cancelButtonAriaLabel: "Skip Bonus",
        //   }).then(async (result) => {
        //     if (result.isConfirmed) {

        //   });
        // }
      } else {
        props.showAlert(__("Login Failed"), response.data.message, true); //isError last param
      }
    }
  };

  const quickLogin = async (email, password) => {
    var response = await api.post("/login", {
      email: email,
      password: password,
      forceLogin: true,
    });
    console.log("logindetails", response);
    setSpinner(false);
    // alert(response.status);
    if (response.status == 200) {
      await auth.login(response.data.user);
      updateUserAuthStatus(response.data.user);
      setLoginBonus(response.data.daily_sc);
      setUserDetails(auth.getUser());
      // setRegistrationSuccessModal(true);
      setShowLoginModal(false);
    } else if (response.status == 503) {
      return new swal({
        className: props.theme,
        title: __("Login Failed"),
        text: "Login failed, please try agian.",
        icon: "error",
      });
    } else {
      return new swal({
        className: props.theme,
        title: __("Login Failed"),
        text: response.data.message,
        icon: "error",
      });
    }
  };

  const [twoFaCode, setTwoFaCode] = useState("");

  const twoFaLogin = async (ev) => {
    ev.preventDefault();
    setMainErrorMgs("");
    setSpinner(true);
    var response = await api.post("/twofa-login", { twofa_code: twoFaCode });
    console.log(response);
    setSpinner(false);
    if (response.status == 200) {
      await auth.login(response.data.user);
      updateUserAuthStatus(response.data.user);
      setLoginBonus(response.data.daily_sc);
      setShowLoginModal(false);
    } else {
      return new swal({
        className: props.theme,
        title: __("Invalid code"),
        text: response.data.message,
        icon: "error",
      });
    }
  };

  const resend2FaCode = async (ev) => {
    ev.preventDefault();

    setMainErrorMgs("");

    setSpinner(true);
    var response = await api.post("/resend-twofa");
    console.log(response);
    setSpinner(false);
    if (response.status == 200) {
      return new swal({
        className: props.theme,
        title: __("2FA code re-sent"),
        text: response.data.message,
        icon: "success",
      });
    }
  };

  const logout = async (ev) => {
    auth.logout();
    setUserDetails(auth.getUser());
  };

  useEffect(() => {
    // getAllCountries();
    // getLanguages();
    // getCurrencies();
    // getRegCampaigns();
  }, []);

  //Getting necessary data for registration END'S

  //Register functionality START'S here
  const [activeStep, setActiveStep] = useState(1);
  const [showPass, setShowPass] = useState(false);
  const [showRePass, setShowRePass] = useState(false);
  const [regErrors, setRegErrors] = useState({});
  // const [registrationForm, setRegistrationForm] = useState({
  //   email: "",
  //   password: "",
  //   rePassword: "",
  //   agreeToTerms: 0,
  //   receiveBonusOffer: 0,
  // });

  const [registrationForm, setRegistrationForm] = useState({
    username: "",
    email: "",
    password: "",
    bday: "",
    bmonth: "",
    byear: "",
    affiliateCode: "",
    referralCode: props.referralCode,
    language: selectedLanguage.code,
    bonusOffer: "no_bonus",
    promoCode: "",
    agreeToTerms: false,
    ageConfirmation: false,
  });

  const updateRegistrationForm = (field, value) => {
    setRegistrationForm({ ...registrationForm, [field]: value });
    console.log("updateRegistrationForm", registrationForm);
  };

  const updateRegInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    var type = target.type;
    if (type == "checkbox") {
      if (target.checked) {
        updateRegistrationForm(field, 1);
      } else {
        updateRegistrationForm(field, 0);
      }
    } else {
      updateRegistrationForm(field, value);
    }
    checkInputReg(ev);
  };

  useEffect(() => {
    if (props.referralCode) {
      updateRegistrationForm("referralCode", props.referralCode);
    }
  }, [props.referralCode]);

  const checkRegDuplicateEmail = async () => {
    // updateRegistrationForm(field, value);
    var response = await api.post("/check-reg-duplicate-email", {
      email: registrationForm.email,
    });
    if (response.status == 200) {
      return true;
    } else {
      return response.data.errors;
    }
  };

  const checkRegDuplicateUsername = async () => {
    // updateRegistrationForm(field, value);
    var response = await api.post("/check-reg-duplicate-username", {
      username: registrationForm.username,
    });
    if (response.status == 200) {
      return true;
    } else {
      return response.data.errors;
    }
  };

  const validateRegisterForm = () => {
    var valid = true;
    var errorsTmp = {};

    if (
      registrationForm.username.length < 2 ||
      registrationForm.username.length > 250
    ) {
      errorsTmp = { ...errorsTmp, username: __("Please enter the user name") };
      valid = false;
    } else {
      var response = checkRegDuplicateUsername(registrationForm.email);
      var promise = Promise.resolve(response);
      promise.then(function (val) {
        if (val == true) {
        } else {
          errorsTmp = {
            ...errorsTmp,
            username: val.username,
          };

          valid = false;
          setRegErrors(errorsTmp);
        }

        console.log("PromisePromisePromise", val);
      });
    }

    if (
      registrationForm.email.length < 2 ||
      registrationForm.email.length > 250
    ) {
      errorsTmp = { ...errorsTmp, email: __("Please enter your email") };
      valid = false;
    }

    if (!emailValidate(registrationForm.email)) {
      errorsTmp = {
        ...errorsTmp,
        email: __("Please enter a valid email address"),
      };
      valid = false;
    } else {
      var response = checkRegDuplicateEmail(registrationForm.email);
      var promise = Promise.resolve(response);
      promise.then(function (val) {
        if (val == true) {
          // setActiveStep(activeStep + 1);
        } else {
          errorsTmp = {
            ...errorsTmp,
            email: val.email,
          };

          valid = false;
          setRegErrors(errorsTmp);
        }

        console.log("PromisePromisePromise", val);
      });
    }

    if (
      registrationForm.password.length < 8 ||
      registrationForm.password.length > 30
    ) {
      errorsTmp = {
        ...errorsTmp,
        password: __("Your password must contain between 8 and 30 characters"),
      };
      valid = false;
    }

    if (!registrationForm.agreeToTerms) {
      errorsTmp = {
        ...errorsTmp,
        agreeToTerms: __("Please check the terms and conditions."),
      };
      valid = false;
    }

    if (!registrationForm.ageConfirmation) {
      errorsTmp = {
        ...errorsTmp,
        ageConfirmation: __("Please check the age confirmation."),
      };
      valid = false;
    }

    setRegErrors(errorsTmp);
    return valid;
  };

  const registrationAction = async (ev) => {
    ev.preventDefault();
    var valid = validateRegisterForm();
    if (valid) {
      setSpinner(true);
      var newRegForm = JSON.parse(JSON.stringify(registrationForm));
      var affToken = ls.get("afftoken");
      if (affToken) {
        newRegForm.affiliateToken = affToken;
      }
      var response = await api.post("/quick-signup", newRegForm);
      setSpinner(false);
      console.log(response.data);
      if (response.status == 200) {
        setShowLoginModal(false);
        // history.push("/registration-complete");
        return new swal({
          className: props.theme,
          title: __("Welcome!"),
          text: __("Let's go play!"),
          icon: "success",
        }).then(function () {
          quickLogin(newRegForm.email, newRegForm.password);
        });
        // history.push("/login");

        // props.showAlert(__("Welcome"), __("Let's go play!"), false, null, 5000);

        setActiveTab("login");
      } else {
        if (response.data.errors) {
          setRegErrors(response.data.errors);
        } else {
          alert(response.data.message);
        }
      }
    }
  };

  //forgot Password functionality START'S here
  const [forGotErrors, setForGotErrors] = useState("");
  const [forgotPassForm, setForgotPassForm] = useState({
    email: "",
  });
  const [sentForgotPassMail, setSentForgotPassMail] = useState(false);

  const updateForgotInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    setForgotPassForm({
      email: value,
    });
  };

  const forgotPassword = async (ev) => {
    ev.preventDefault();
    var errorsTmp = {};

    if (!emailValidate(forgotPassForm.email)) {
      setForGotErrors(__("Please enter a valid email address"));
    } else {
      var response = await api.post("/request-reset-password", forgotPassForm);
      console.log(response);
      if (response.status == 200) {
        // setRegistrationSuccessModal(true);
        setSentForgotPassMail(true);
      } else {
        setForGotErrors(response.data.message);
      }
    }
  };
  //forgot password functionality END'S here

  const [acceptField, setAcceptField] = useState("");

  const checkInput = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    // alert(field);
    if (field == "email") {
      if (emailValidate(value)) {
        setAcceptField({ ...acceptField, email: 1 });
        setErrors({ ...errors, email: "" });
      } else {
        setAcceptField({ ...acceptField, email: 2 });
        setErrors({ ...errors, email: __("Your email not valid") });
      }
    }

    if (field == "password") {
      if (value.length < 2 || value.length > 250) {
        setAcceptField({ ...acceptField, password: 2 });
        setErrors({ ...errors, password: __("Please enter your password") });
      } else {
        setAcceptField({ ...acceptField, password: 1 });
        setErrors({ ...errors, password: "" });
      }
    }
  };

  const checkInputReg = (ev) => {
    var target = ev.target;
    var field = target.name;
    var value = target.value;
    // alert(field);
    if (field == "email") {
      if (emailValidate(value)) {
        setAcceptField({ ...acceptField, email: 1 });
        setRegErrors({ ...regErrors, email: "" });
      } else {
        setAcceptField({ ...acceptField, email: 2 });
        setRegErrors({ ...regErrors, email: __("Your email not valid") });
      }
    }

    if (field == "password") {
      if (value.length < 2 || value.length > 250) {
        setAcceptField({ ...acceptField, password: 2 });
        setRegErrors({
          ...regErrors,
          password: __("Please enter your password"),
        });
      } else {
        setAcceptField({ ...acceptField, password: 1 });
        setRegErrors({ ...regErrors, password: "" });
      }
    }

    if (field == "username") {
      if (value.length < 2 || value.length > 250) {
        setAcceptField({ ...acceptField, username: 2 });
        setRegErrors({
          ...regErrors,
          username: __("Please enter your username"),
        });
      } else {
        setAcceptField({ ...acceptField, username: 1 });
        setRegErrors({ ...regErrors, username: "" });
      }
    }
  };
  return (
    <>
      {spinner && <Spinner />}
      <Modal
        size={activeTab == "Sign up" ? "lg" : "sm"}
        show={showLoginModal}
        onHide={closeLoginModal}
        centered
        className={
          "loginRegModal  " + (activeTab == "Sign up" ? "regSize" : "loginSize")
        }
      >
        <Modal.Body
          className={
            activeTab === "Sign up" ? "login_modal reg_section" : "login_modal "
          }
        >
          <div onClick={closeLoginModal} className="modal_close_reg">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.68964 12.2073L0.9655 4.78928C0.551706 4.39188 0.275845 3.79579 0.275845 3.1997C0.275845 2.60361 0.551706 2.00752 0.9655 1.61012C1.86205 0.7491 3.37929 0.7491 4.27584 1.61012L12 9.02815L19.7241 1.61012C20.6207 0.7491 22.1379 0.7491 23.0345 1.61012C23.4483 2.00752 23.7241 2.60361 23.7241 3.1997C23.7241 3.79579 23.4483 4.39188 23.0345 4.78928L15.3103 12.2073L23.0345 19.6253C23.4483 20.0227 23.7241 20.6188 23.7241 21.2149C23.7241 21.811 23.4483 22.4071 23.0345 22.8045C22.6207 23.2019 22 23.4668 21.3793 23.4668C20.7586 23.4668 20.1379 23.2019 19.7241 22.8045L12 15.3865L4.27584 22.8045C3.37929 23.6655 1.86205 23.6655 0.9655 22.8045C0.551706 22.4071 0.275845 21.811 0.275845 21.2149C0.275845 20.6188 0.551706 20.0227 0.9655 19.6253L8.68964 12.2073Z"
                fill="#10294F"
              />
            </svg>
          </div>

          <div className="container-fluid">
            <div className="row">
              <div
                className={
                  activeTab === "Sign up"
                    ? "d-none d-md-block col-md-6 modalBg register"
                    : "d-none  col-md-6 modalBg login"
                }
              >
                {activeTab === "Sign up" && (
                  <>
                    <Logo />

                    <div class="info signInPromotion">
                      <img
                        className="dimondTop"
                        src={require("assets/sweeplay/images/cashierModal/diamond-1.webp")}
                      />
                      <span class="title">{__("Welcome offer")}</span>
                      <div class="count desktop">
                        <div class="prize gcCoins">
                          <img
                            style={{ marginRight: "5px" }}
                            src={
                              require("assets/sweeplay/images/cashierModal/gold-coin-sweeplay.svg")
                                .default
                            }
                          />
                          2,000,000
                        </div>
                        <div className="prize gcCoins">
                          {__("GET")}{" "}
                          <img
                            src={
                              require("assets/sweeplay/images/cashierModal/sweeplay-coin.svg")
                                .default
                            }
                          />{" "}
                          30 {__("FOR FREE")}
                        </div>
                      </div>
                      <div class="foot">
                        <span class="tempe">$19,99</span>{" "}
                        <span class="topeka">{__("was")}</span>{" "}
                        <span class="stripText ">$40,00</span>
                      </div>
                      <img
                        className="dimondBottom"
                        src={require("assets/sweeplay/images/cashierModal/diamond-2.webp")}
                      />
                    </div>
                  </>
                )}
              </div>

              <div
                className={activeTab === "Sign up" ? "col-md-6" : "col-md-12"}
              >
                <div className="login_reg_area">
                  <Alert show={alertMsg.showAlert} variant={alertMsg.variant}>
                    {alertMsg.message}
                  </Alert>
                  {activeTab === "login" ? (
                    <div className="modal_headline">{__("Log In")}</div>
                  ) : (
                    <>
                      <div className="modal_headline">{__("Join Now")}</div>
                      {props.isMobileDevice && (
                        <>
                          <div class="info signInPromotion isMobileDevice">
                            <img
                              className="dimondTop"
                              src={require("assets/sweeplay/images/cashierModal/diamond-1.webp")}
                            />
                            <span class="title">{__("Welcome offer")}</span>
                            <div class="count desktop">
                              <div class="prize gcCoins">
                                <img
                                  style={{ marginRight: "5px" }}
                                  src={
                                    require("assets/sweeplay/images/cashierModal/gold-coin-sweeplay.svg")
                                      .default
                                  }
                                />
                                2,000,000
                              </div>
                              <div className="prize gcCoins">
                                {__("GET")}{" "}
                                <img
                                  src={
                                    require("assets/sweeplay/images/cashierModal/sweeplay-coin.svg")
                                      .default
                                  }
                                />{" "}
                                30 {__("FOR FREE")}
                              </div>
                            </div>
                            <div class="foot">
                              <span class="tempe">$19,99</span>{" "}
                              <span class="topeka">{__("was")}</span>{" "}
                              <span class="stripText ">$40,00</span>
                            </div>
                            <img
                              className="dimondBottom"
                              src={require("assets/sweeplay/images/cashierModal/diamond-2.webp")}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}

                  <SocialLogin callFrom="modal" {...props} />
                  {activeTab === "login" && (
                    <>
                      <div className="form_area">
                        <form autocomplete="off" onSubmit={login}>
                          <div className="input_item">
                            <div className="name">
                              {__("Email")}
                              <span className="requried_icon">*</span>
                            </div>
                            <input
                              type="text"
                              name="email"
                              value={loginForm.email}
                              onChange={updateInput}
                              onBlur={checkInput}
                              autocomplete="off"
                              className={
                                " name_value " + (errors.email ? "error" : "")
                              }
                              placeholder="Your Email"
                            />
                            <div className="errorMsg">{errors.email}</div>
                          </div>
                          <div className="input_item">
                            <div className="name">
                              {__("Password")}
                              <span className="requried_icon">*</span>
                              <span
                                onClick={openRecoverPssModal}
                                className="forgot_pass_link"
                              >
                                {__("Forgot?")}
                              </span>
                            </div>
                            <input
                              type="password"
                              name="password"
                              className={
                                " name_value " +
                                (errors.password ? "error" : "")
                              }
                              value={loginForm.password}
                              autocomplete="off"
                              onChange={updateInput}
                              onBlur={checkInput}
                              placeholder="Enter Password"
                            />

                            <div className="errorMsg">{errors.password}</div>
                            <Link
                              hreflang={Translator.getSelectedLanguage().code}
                              onClick={openRecoverPssModal}
                              className="forgot_pass_link"
                            >
                              {__("Forgot?")}
                            </Link>
                          </div>
                          <div className="login_button">
                            <button
                              type="submit"
                              className="commonBtn  purpleBtn"
                            >
                              {__("Log in")}
                            </button>
                          </div>
                        </form>
                      </div>
                    </>
                  )}

                  {activeTab === "Sign up" && (
                    <>
                      <form autocomplete="off" onSubmit={registrationAction}>
                        {activeStep === 1 && (
                          <>
                            <div className="form_area">
                              <div className="input_item">
                                <div className="name">
                                  {__("Username")}
                                  <span className="requried_icon">*</span>
                                </div>
                                <input
                                  type="text"
                                  name="username"
                                  placeholder={__("Username")}
                                  onChange={updateRegInput}
                                  onBlur={checkInputReg}
                                  value={registrationForm.username}
                                  className={
                                    " name_value " +
                                    (regErrors.username ? "error" : "")
                                  }
                                  autocomplete="off"
                                />
                                <div class="errorMsg">{regErrors.username}</div>
                              </div>
                              <div className="input_item">
                                <div className="name">
                                  {__("Password")}
                                  <span className="requried_icon">*</span>
                                </div>
                                <input
                                  type={showPass ? "text" : "password"}
                                  name="password"
                                  placeholder={__("Enter Password")}
                                  onChange={updateRegInput}
                                  onBlur={checkInputReg}
                                  value={registrationForm.password}
                                  className={
                                    " name_value " +
                                    (regErrors.password ? "error" : "")
                                  }
                                  autocomplete="off"
                                />

                                {showPass && (
                                  <i
                                    class="show_hide_pass fa fa-eye-slash"
                                    onClick={() => {
                                      setShowPass(false);
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                )}
                                {!showPass && (
                                  <i
                                    className="show_hide_pass fa fa-eye"
                                    onClick={() => {
                                      setShowPass(true);
                                    }}
                                    aria-hidden="true"
                                  ></i>
                                )}

                                <div class="errorMsg">{regErrors.password}</div>
                              </div>

                              <div className="input_item">
                                <div className="name">
                                  {__("Email")}
                                  <span className="requried_icon">*</span>
                                </div>
                                <input
                                  type="email"
                                  name="email"
                                  placeholder={__("Your Email")}
                                  onChange={updateRegInput}
                                  onBlur={checkInputReg}
                                  value={registrationForm.email}
                                  className={
                                    " name_value " +
                                    (regErrors.email ? "error" : "")
                                  }
                                />
                                <div class="errorMsg">{regErrors.email}</div>
                              </div>

                              <Accordion className="promoAccordion">
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    <div className="titleText">
                                      {__("I Have a promo code")}
                                    </div>
                                    <div className="titleLine"></div>
                                  </Accordion.Header>
                                  <Accordion.Body>
                                    <br />
                                    <div className="input_item">
                                      <div className="name">
                                        {__("Affiliate Code")} ({__("Optional")}
                                        )
                                      </div>
                                      <input
                                        type="text"
                                        name="affiliateCode"
                                        placeholder={__("Affiliate Code")}
                                        onChange={updateRegInput}
                                        value={registrationForm.affiliateCode}
                                        className={
                                          " name_value " +
                                          (regErrors.affiliateCode
                                            ? "error"
                                            : "")
                                        }
                                      />
                                    </div>

                                    <div className="input_item">
                                      <div className="name">
                                        {__("Bonus Code")} ({__("Optional")})
                                      </div>
                                      <input
                                        type="text"
                                        name="referralCode"
                                        placeholder={__("Bonus Code")}
                                        onChange={updateRegInput}
                                        value={registrationForm.referralCode}
                                        className={
                                          " name_value " +
                                          (regErrors.referralCode
                                            ? "error"
                                            : "")
                                        }
                                      />
                                    </div>
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>

                              <div className="reg_bouns_container">
                                <div className="reg_bouns_area">
                                  <RegCampaigns
                                    onChange={(value) =>
                                      updateRegistrationForm(
                                        "bonusOffer",
                                        value
                                      )
                                    }
                                    onChangePromoCode={(value) =>
                                      updateRegistrationForm("promoCode", value)
                                    }
                                    promoCode={registrationForm.promoCode}
                                    value={registrationForm.bonusOffer}
                                    currency={"USD"}
                                    language={registrationForm.language}
                                  />
                                </div>
                              </div>

                              <div className="input_item">
                                <div className="reg_check_area">
                                  <input
                                    type="checkbox"
                                    className="stepTerms"
                                    id="ageConfirmation"
                                    value={registrationForm.ageConfirmation}
                                    name="ageConfirmation"
                                    onChange={updateRegInput}
                                  />
                                  <label
                                    for="ageConfirmation"
                                    className="check_area"
                                  >
                                    <div className="check_check_and_text_area">
                                      <div className="check_box">
                                        <div className="check_checked_box"></div>
                                      </div>
                                      <div className="check_text">
                                        {__(
                                          "I declare that I am 18 years or older, located in a permitted territory and have no self-exclusions."
                                        )}
                                        <span className="requried_icon">*</span>
                                      </div>
                                    </div>
                                  </label>
                                  <div className="errorMsg">
                                    {regErrors.ageConfirmation}
                                  </div>
                                </div>

                                <div className="reg_check_area">
                                  <input
                                    type="checkbox"
                                    className="stepTerms"
                                    id="agreeToTerms"
                                    value={registrationForm.agreeToTerms}
                                    name="agreeToTerms"
                                    onChange={updateRegInput}
                                  />
                                  <label
                                    for="agreeToTerms"
                                    className="check_area"
                                  >
                                    <div className="check_check_and_text_area">
                                      <div className="check_box">
                                        <div className="check_checked_box"></div>
                                      </div>
                                      <div className="check_text">
                                        {__("I have read and accept the") + " "}
                                        <Link
                                          hreflang={
                                            Translator.getSelectedLanguage()
                                              .code
                                          }
                                          rel="nofollow noopener noreferrer"
                                          target="_blank"
                                          to="/terms-and-conditions"
                                        >
                                          {__("Terms of service")}
                                        </Link>
                                        ,{" "}
                                        <Link
                                          hreflang={
                                            Translator.getSelectedLanguage()
                                              .code
                                          }
                                          rel="nofollow noopener noreferrer"
                                          target="_blank"
                                          to="/privacy-policy"
                                        >
                                          {__("Privacy policy")}
                                        </Link>
                                        ,{" "}
                                        <Link
                                          hreflang={
                                            Translator.getSelectedLanguage()
                                              .code
                                          }
                                          rel="nofollow noopener noreferrer"
                                          target="_blank"
                                          to="/responsible-gaming"
                                        >
                                          {__("Responsible gaming policy")}
                                        </Link>
                                        ,{" "}
                                        <Link
                                          hreflang={
                                            Translator.getSelectedLanguage()
                                              .code
                                          }
                                          rel="nofollow noopener noreferrer"
                                          target="_blank"
                                          to="/aml-policy"
                                        >
                                          {__("Payment policy")}
                                        </Link>{" "}
                                        {__("and any other policies.")}
                                        <span className="requried_icon">*</span>
                                      </div>
                                    </div>
                                  </label>
                                  <div className="errorMsg">
                                    {regErrors.agreeToTerms}
                                  </div>
                                </div>
                              </div>

                              <div className="login_button">
                                <button
                                  type="submit"
                                  className="commonBtn purpleBtn"
                                  // disabled={
                                  //   !registrationForm.ageConfirmation ||
                                  //   !registrationForm.agreeToTerms
                                  // }
                                >
                                  {__("Play Now")}
                                </button>
                              </div>
                            </div>
                          </>
                        )}
                      </form>
                    </>
                  )}

                  <div className="privacy_text d-none">
                    {__("This site is protected by Captcha and our")}
                    <Link
                      hreflang={Translator.getSelectedLanguage().code}
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      to="/privacy-policy"
                    >
                      {" "}
                      {__("Privacy Policy")}
                    </Link>{" "}
                    {__("and")}{" "}
                    <Link
                      hreflang={Translator.getSelectedLanguage().code}
                      rel="nofollow noopener noreferrer"
                      target="_blank"
                      to="/terms-and-conditions"
                    >
                      {" "}
                      {__("Terms & Conditions")}
                    </Link>{" "}
                    {__("apply")}
                  </div>

                  {activeTab === "Sign up" && (
                    <div className="routing_text">
                      {__("Already have an account?")}{" "}
                      <Link
                        hreflang={Translator.getSelectedLanguage().code}
                        onClick={() => {
                          setActiveTab("login");
                        }}
                      >
                        {__("Log in")}
                      </Link>
                    </div>
                  )}
                  {activeTab === "login" && (
                    <div className="routing_text">
                      {__("Don't have an account?")}{" "}
                      <Link
                        hreflang={Translator.getSelectedLanguage().code}
                        onClick={() => {
                          setActiveTab("Sign up");
                        }}
                      >
                        {__("Sign up")}
                      </Link>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal size="lg" show={userDetails && userDetails.twoFaNeeded}>
        <Modal.Body className="login_modal">
          <div className="container-fluid">
            <div className="row">
              <div
                className={
                  activeTab === "Sign up"
                    ? "d-none d-md-block col-md-6 modalBg register"
                    : "d-none d-md-block col-md-6 modalBg login"
                }
              ></div>
              <div className="col-md-6">
                <div className="login_reg_area">
                  <Alert show={alertMsg.showAlert} variant={alertMsg.variant}>
                    {alertMsg.message}
                  </Alert>

                  <div className="form_area">
                    <form onSubmit={twoFaLogin}>
                      <div className="input_item">
                        <div className="name">
                          {__("Two FA Code")}
                          <span className="requried_icon">*</span>
                          <br />
                          <span>
                            {__(
                              "Please enter the twofa code sent to your registered email."
                            )}
                          </span>
                        </div>

                        <input
                          type="text"
                          name="twofa_code"
                          value={twoFaCode}
                          onChange={(ev) => setTwoFaCode(ev.target.value)}
                          className={
                            " name_value " + (errors.twofa_code ? "error" : "")
                          }
                        />
                        <div className="errorMsg">{errors.twofa_code}</div>
                      </div>
                      <div className="login_button">
                        <button
                          type="submit"
                          className="commonBtn filledBtn loginBtn"
                        >
                          {__("Log in")}
                        </button>
                      </div>
                    </form>
                  </div>

                  <div className="privacy_text">
                    <a href="#" onClick={resend2FaCode}>
                      {" "}
                      {__("Resend Code")}
                    </a>{" "}
                    {__("or")}
                    <a href="#" onClick={logout}>
                      {" "}
                      {__("Login with a new account")}
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal show={recoverPssModal} onHide={closeRecoverPssModal}>
        <Modal.Body className="login_modal forgot_pass">
          <div onClick={closeRecoverPssModal} className="modal_close_reg">
            <svg
              width="24"
              height="24"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M8.68964 12.2073L0.9655 4.78928C0.551706 4.39188 0.275845 3.79579 0.275845 3.1997C0.275845 2.60361 0.551706 2.00752 0.9655 1.61012C1.86205 0.7491 3.37929 0.7491 4.27584 1.61012L12 9.02815L19.7241 1.61012C20.6207 0.7491 22.1379 0.7491 23.0345 1.61012C23.4483 2.00752 23.7241 2.60361 23.7241 3.1997C23.7241 3.79579 23.4483 4.39188 23.0345 4.78928L15.3103 12.2073L23.0345 19.6253C23.4483 20.0227 23.7241 20.6188 23.7241 21.2149C23.7241 21.811 23.4483 22.4071 23.0345 22.8045C22.6207 23.2019 22 23.4668 21.3793 23.4668C20.7586 23.4668 20.1379 23.2019 19.7241 22.8045L12 15.3865L4.27584 22.8045C3.37929 23.6655 1.86205 23.6655 0.9655 22.8045C0.551706 22.4071 0.275845 21.811 0.275845 21.2149C0.275845 20.6188 0.551706 20.0227 0.9655 19.6253L8.68964 12.2073Z"
                fill="#10294F"
              />
            </svg>
          </div>
          <div className="modal_headline">{__("Password Reset")}</div>
          {!sentForgotPassMail && (
            <>
              <form onSubmit={forgotPassword}>
                <div className="form_area">
                  <div className="input_item">
                    <div className="name">
                      {__("Email")}
                      <span className="requried_icon">*</span>
                    </div>
                    <input
                      type="email"
                      name="email"
                      value={forgotPassForm.email}
                      onChange={updateForgotInput}
                      className={" name_value " + (forGotErrors ? "error" : "")}
                    />
                    <div className="errorMsg">{forGotErrors}</div>
                  </div>

                  <div className="login_button">
                    <button
                      type="submit"
                      className="commonBtn filledBtn loginBtn"
                    >
                      {__("Send Recover Link")}
                    </button>
                  </div>
                </div>
              </form>
            </>
          )}

          {sentForgotPassMail && (
            <p style={{ color: "white" }} className="recovery_success">
              {__(
                "A password recovery email has been sent to your provided email address."
              )}
            </p>
          )}
        </Modal.Body>
      </Modal>
    </>
  );
};

export default LoginReg;
