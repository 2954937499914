import React, { useState, useEffect, useRef } from "react";
import { ProgressBar } from "react-bootstrap";
import LoadMore from "elements/LoadMore";
import GameItem from "elements/GameElements/GameItem";
// import SearchBox from "elements/SearchBox";
import { Link, useParams, useHistory } from "react-router-dom";
import { Modal } from "react-bootstrap";
import api from "services/api";
import Translator, { __ } from "services/Translator";

// import JackpotTicker from "elements/JackpotTicker";

export default function CategoryGames(props) {
  const params = useParams();
  const category = params.category;
  const history = useHistory();
  const providers = props.providers;
  const selectedProviders = props.selectedProviders;
  const setSelectedProviders = props.setSelectedProviders;

  const [providersModal, setProvidersModal] = useState(false);
  const closeAllModals = () => {
    setProvidersModal(false);
  };
  const toggleProviersModal = () => {
    setProvidersModal(!providersModal);
  };
  const toggleSelectedProvider = (providerId) => {
    var providersClone = JSON.parse(JSON.stringify(selectedProviders));
    if (providersClone.indexOf(providerId) === -1) {
      providersClone.push(providerId);
    } else {
      providersClone.splice(providersClone.indexOf(providerId), 1);
    }
    setSelectedProviders(providersClone);
    history.push("/games/all");
  };

  const clearAllProviders = () => {
    setSelectedProviders([]);
  };
  // const __ = (text) => text;

  const [searchStr, setSearchStr] = useState("");
  const [searchSuggestions, setSearchSuggestions] = useState([]);
  const searchTextBox = useRef(null);

  // expand searcbar
  const [expandSearchBar, setExpandSearchBar] = useState(true);

  const toggleExpandedSearchBar = (force) => {
    if (expandSearchBar) {
      setSearchSuggestions([]);
      if (force === true) {
        setSearchStr("");
        setExpandSearchBar(false);
      } else {
        if (searchStr.length === 0) {
          setExpandSearchBar(false);
        }
      }
    } else {
      setExpandSearchBar(true);
      searchTextBox.current.focus();
    }
  };

  const getSearchSuggestions = async () => {
    if (searchStr.length >= 2) {
      var response = await api.get("/games/search-suggestions", {
        query: searchStr,
        // providers: props.selectedProviders,
        providers: [],
        extensiveInfo: true,
      });
      if (response.status === 200) {
        setSearchSuggestions(response.data);
      }
    } else {
      setSearchSuggestions([]);
    }
  };

  useEffect(() => {
    getSearchSuggestions();
  }, [searchStr]);

  const doSearchByGameName = (text) => {
    props.setSearchStr(text);
    toggleExpandedSearchBar(true);
    props.setSelectedProviders([]);
    history.push("/games/search");
  };

  return (
    <>
      <section className="game_section">
        <div className="section_headline margin_bottom_30">
          <div className="headline_text">
            {props.category == "popular" ? "Top Games" : props.category}
          </div>
        </div>

        <div className="gamesLists ">
          {props.gameList.map((game, index) => (
            <GameItem
              game={game}
              isAuthenticated={props.isAuthenticated}
              landingType={props.landingType}
              key={index}
              props={props}
            />
          ))}
        </div>

        {props.showPagination && (
          <>
            <LoadMore
              className="margin_bottom_15"
              data={props.pagination}
              onPageChange={props.onPageChange}
            />

            <div className="gameLoadingProgress d-none">
              <div className="gameLoadingInfo text-white margin_bottom_15">
                {__("You've viewed")} {props.gameLoaded} {__("of")}{" "}
                {props.totalGame} {__("games")}
                {/* <div className="gameLoaded">{props.gameLoaded}</div>
                <div className="totalGame"> {props.totalGame}</div> */}
              </div>

              <ProgressBar
                className={
                  "games_count_progress margin_bottom_30 " + props.landingType
                }
                variant="success"
                now={(props.gameLoaded / props.totalGame) * 100}
              />
            </div>
          </>
        )}

        <Modal size="lg" show={providersModal} onHide={closeAllModals}>
          <Modal.Body className="providers_modal">
            <div onClick={closeAllModals} className="modal_close">
              <i className="fal fa-times"></i>
            </div>

            <div className="modal_headline">{__("Filter By Providers")}</div>
            <div className="providers_list">
              <Link
                hreflang={Translator.getSelectedLanguage().code}
                onClick={clearAllProviders}
                className={
                  "common_btn small " +
                  (selectedProviders.length === 0 ? "active" : "inactive")
                }
              >
                {__("All Providers")}
              </Link>

              {providers.map((item, index) => (
                <Link
                  hreflang={Translator.getSelectedLanguage().code}
                  onClick={() => toggleSelectedProvider(item.provider_id)}
                  key={index}
                  className={
                    "common_btn small " +
                    (item.name.length > 12 ? "smallText " : "") +
                    (selectedProviders.indexOf(item.provider_id) !== -1
                      ? "active"
                      : "inactive")
                  }
                >
                  {item.name}
                </Link>
              ))}
            </div>
          </Modal.Body>
        </Modal>
      </section>
    </>
  );
}
