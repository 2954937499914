import ls from "local-storage";
import Translator, { __ } from "services/Translator";
//Auth module
import PrivateRoute from "./PrivateRoute";
import OpenRoute from "./OpenRoute";

// import external modules
import React, { Component, lazy } from "react";
import { BrowserRouter, Switch, Redirect } from "react-router-dom";

// Main Layout

import Landing from "pages/Landing";
import GamePlay from "pages/GamePlay";
import Terms from "pages/Terms";
import Privacy from "pages/Privacy";
import SweepsRules from "pages/SweepsRules";
import RedeemRules from "pages/RedeemRules";
import Redeem from "pages/Redeem";
import ResponsibleGaming from "pages/ResponsibleGaming";
import Profile from "pages/Profile";
import About from "pages/About";
import Contact from "pages/Contact";
import ContactDetails from "pages/ContactDetails";
import Home from "pages/Home";
import RecoverPassword from "pages/auth/RecoverPassword";
import ResetPassword from "pages/auth/ResetPassword";
import Messages from "pages/Messages";

import Games from "pages/Games";
import BetHistory from "pages/players/BetHistory";
import AccountActivity from "pages/players/AccountActivity";
import ResponsibleGamingSettings from "pages/players/ResponsibleGamingSettings";
import Verify from "pages/players/Verify";

import Promotions from "pages/Promotions";
import Tournaments from "pages/Tournaments";
import FavoriteGames from "pages/FavoriteGames";
import Transactions from "pages/players/Transactions";
import LoyaltyProgram from "pages/LoyaltyProgram";
import Affiliates from "pages/Affiliates";
import UserDashboard from "pages/UserDashboard";

// const Landing = lazy(() => import("../pages/Landing"));
// import { BrowserRouter, Switch, Redirect } from "react-router-dom";
class OpenRoutes extends Component {
  // const history = useHistory()
  //   useEffect(() => {
  //     const unlisten = history.listen((location, action) => {
  //       if (action !== 'POP') {
  //         window.scrollTo(0, 0)
  //       {
  //     })
  //     return () => unlisten()
  //   }, [])

  render() {
    return (
      <BrowserRouter
        basename={"/" + Translator.getSelectedLanguage().code + "/"}
      >
        <Switch>
          {/* Auth pages route */}
          <OpenRoute exact path="/" component={Landing}></OpenRoute>
          <OpenRoute
            exact
            path="/social/auth/response/google/"
            component={Landing}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/tournaments"
            component={Tournaments}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/play/:slug/:mode"
            component={GamePlay}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/terms-and-conditions"
            component={Terms}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/privacy-policy"
            component={Privacy}
          ></OpenRoute>
          <OpenRoute exact path="/affiliates" component={Affiliates} />
          <OpenRoute
            exact
            path="/responsible-social-gameplay-policy"
            component={ResponsibleGaming}
          ></OpenRoute>
          <OpenRoute exact path="/about-us" component={About}></OpenRoute>
          <OpenRoute exact path="/contact-us" component={Contact}></OpenRoute>
          <OpenRoute
            exact
            path="/contact-details"
            component={ContactDetails}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/sweeps-rules"
            component={SweepsRules}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/loyalty-program"
            component={LoyaltyProgram}
          ></OpenRoute>

          <OpenRoute exact path="/login" component={Landing}></OpenRoute>
          <OpenRoute exact path="/register" component={Landing}></OpenRoute>
          <OpenRoute
            exact
            path="/recover-password"
            component={Landing}
          ></OpenRoute>
          <OpenRoute
            exact
            path="/reset-password/:userId/:code"
            component={ResetPassword}
          ></OpenRoute>

          <OpenRoute exact path="/home" component={Home}></OpenRoute>

          <OpenRoute
            exact
            path="/games/:gameType"
            component={Games}
          ></OpenRoute>
          {/* <OpenRoute exact path="/games/:slots" component={Games}></OpenRoute>
          <OpenRoute exact path="/games/:jackpot" component={Jackpot}></OpenRoute>
          <OpenRoute
            exact
            path="/games/:table-games"
            component={TableGames}
          ></OpenRoute> */}

          <OpenRoute exact path="/profile" component={Profile}></OpenRoute>
          <OpenRoute
            exact
            path="/promotions"
            component={Promotions}
          ></OpenRoute>
          {
            // <OpenRoute exact path="/terms" component={Terms}></OpenRoute>
            // <OpenRoute exact path="/privacy" component={Privacy}></OpenRoute>
            // <OpenRoute exact path="/responsible-gaming" component={ResponsibleGaming}></OpenRoute>
          }

          <Redirect path="*" to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

class PrivateRoutes extends Component {
  render() {
    return (
      <BrowserRouter basename="/">
        <Switch>
          {/*Employee routing*/}
          <PrivateRoute exact path="/" component={Landing}></PrivateRoute>
          <PrivateRoute
            exact
            path="/social/auth/response/google/"
            component={Landing}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/payment-status/:status?"
            component={Landing}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/play/:slug/:mode"
            component={GamePlay}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/terms-and-conditions"
            component={Terms}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/privacy-policy"
            component={Privacy}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/responsible-social-gameplay-policy"
            component={ResponsibleGaming}
          ></PrivateRoute>
          <PrivateRoute exact path="/about-us" component={About}></PrivateRoute>
          <PrivateRoute
            exact
            path="/contact-us"
            component={Contact}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/contact-details"
            component={ContactDetails}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/sweeps-rules"
            component={SweepsRules}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/redeem-rules"
            component={RedeemRules}
          ></PrivateRoute>
          <PrivateRoute exact path="/redeem" component={Redeem}></PrivateRoute>
          <PrivateRoute exact path="/affiliates" component={Affiliates} />
          <PrivateRoute exact path="/home" component={Home}></PrivateRoute>

          <PrivateRoute
            exact
            path="/games/:gameType"
            component={Games}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/favorite-games"
            component={FavoriteGames}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/loyalty-program"
            component={LoyaltyProgram}
          ></PrivateRoute>
          {/* <PrivateRoute
            exact
            path="/games/:gameType"
            component={Jackpot}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/games/:gameType"
            component={TableGames}
          ></PrivateRoute> */}
          <PrivateRoute
            exact
            path={"/dashboard/:tab?"}
            component={UserDashboard}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/profile"
            component={Profile}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/gameplay-history"
            component={BetHistory}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/history"
            component={Transactions}
          ></PrivateRoute>

          <PrivateRoute
            exact
            path="/account-activity"
            component={AccountActivity}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/responsible-gaming-settings"
            component={ResponsibleGamingSettings}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/account-verification"
            component={Verify}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/messages"
            component={Messages}
          ></PrivateRoute>
          <PrivateRoute
            exact
            path="/promotions"
            component={Promotions}
          ></PrivateRoute>

          <Redirect path="*" to="/" />
        </Switch>
      </BrowserRouter>
    );
  }
}

export { OpenRoutes, PrivateRoutes };
