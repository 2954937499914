import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Translator, { __ } from "services/Translator";

const Logo = (props) => {
  // const menuOpen = props.menuOpen;
  const [categoryName, setCategoryName] = useState("Slots");

  return (
    <>
      <Link
        hreflang={Translator.getSelectedLanguage().code}
        to="/"
        className="logo"
      >
        <img
          alt=""
          src={require("../assets/sweeplay/images/logos/logo.svg").default}
        />
        {!props.isMobileDevice && <span>Sweeplay</span>}
      </Link>
    </>
  );
};

export default Logo;
