import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";

import api from "../services/api";
import auth from "../services/auth";
import AppContext from "../contexts/AppContext";
import emailValidate from "../services/emailValidate";
import ls from "local-storage";
import { Button } from "react-bootstrap";
// import  "bootstrap/less/bootstrap.less";
import Translator, { __ } from "services/Translator";
import Pagination from "react-js-pagination";
export default function Profile(props) {
  const [activePage, setActivePage] = useState(4);
  const [activeContent, setActiveContent] = useState("settings");
  const toggleContent = (tabName) => {
    setActiveContent(tabName);
  };

  const [identityFrontFileName, setIdentityFrontFileName] = useState();
  const [identityFrontFile, setIdentityFrontFile] = useState();
  // console.log(identityFrontFile);
  const pickIdentityFront = (event) => {
    setIdentityFrontFileName(event.target.files[0].name);
    setIdentityFrontFile(event.target.files[0]);
  };

  const [identityBackFileName, setIdentityBackFileName] = useState();
  const [identityBackFile, setIdentityBackFile] = useState();
  // console.log(identityBackFile);
  const pickIdentityBack = (event) => {
    setIdentityBackFileName(event.target.files[0].name);
    setIdentityBackFile(event.target.files[0]);
  };

  const [fundFileName, setFundFileName] = useState();
  const [fundFile, setFundFile] = useState();
  const pickFundFile = (event) => {
    setFundFileName(event.target.files[0].name);
    setFundFile(event.target.files[0]);
  };

  const [addressFileName, setAddressFileName] = useState();
  const [addressFile, setAddressFile] = useState();
  const pickAddressFile = (event) => {
    setAddressFileName(event.target.files[0].name);
    setAddressFile(event.target.files[0]);
    // console.log("Hello This is address utilities", addressFile);
  };

  return (
    <div className="profile">
      <div className="container">
        <div className="row">
          <div className="col-md-3">
            <div className="profile_left_menu">
              <ul>
                <li className={activeContent === "settings" ? "active" : ""}>
                  <Link
                    hreflang={Translator.getSelectedLanguage().code}
                    onClick={() => toggleContent("settings")}
                    to="#"
                  >
                    Settings
                  </Link>
                </li>
                <li className={activeContent === "vip" ? "active" : ""}>
                  <Link
                    hreflang={Translator.getSelectedLanguage().code}
                    onClick={() => toggleContent("vip")}
                    to="#"
                  >
                    Vip
                  </Link>
                </li>
                <li
                  className={activeContent === "transactions" ? "active" : ""}
                >
                  <Link
                    hreflang={Translator.getSelectedLanguage().code}
                    onClick={() => toggleContent("transactions")}
                    to="#"
                  >
                    Transactions
                  </Link>
                </li>
                <li className={activeContent === "bet history" ? "active" : ""}>
                  <Link
                    hreflang={Translator.getSelectedLanguage().code}
                    onClick={() => toggleContent("bet history")}
                    to="#"
                  >
                    Bet History
                  </Link>
                </li>
                <li className={activeContent === "verify" ? "active" : ""}>
                  <Link
                    hreflang={Translator.getSelectedLanguage().code}
                    onClick={() => toggleContent("verify")}
                    to="#"
                  >
                    Verify
                  </Link>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-md-9">
            <div className="profile_right_content">
              {activeContent === "settings" && (
                <>
                  <div className="profile_headline">
                    Email
                    <span className="verified_badge">Verified</span>
                  </div>
                  <div className="clearfix"></div>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form_area">
                        <div className="input_item">
                          <div className="name">
                            Email<span className="requried_icon">*</span>
                          </div>
                          <input
                            type="email"
                            name="email"
                            value="geor*********@gmail.com"
                            className="form-control name_value"
                          />
                        </div>
                        <div className="login_button">
                          <Button className="login_btn resend_btn pad_bottom_40">
                            Resend
                          </Button>
                          <Button className="login_btn save_changes_btn pad_bottom_40">
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile_headline">Two Factor</div>
                  <div className="row">
                    <div className="col-md-8">
                      <div className="form_area">
                        <div className="input_item">
                          <div className="name">
                            Copy this code to your authenticator app
                          </div>
                          <input
                            type="text"
                            name="code"
                            value="GEZCMXKEOZ3UIZJ2PU4VCNZYLJRU4UZRN5UX2J"
                            className="form-control name_value"
                          />
                          <i className="fal fa-copy in_input_icon"></i>
                        </div>

                        <div className="input_item">
                          <div className="qr_code">
                            <img
                              src={
                                require("assets/images/icons/qr-code.svg")
                                  .default
                              }
                            />
                          </div>
                          <div className="name">Don't let anyone see this!</div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form_area">
                        <div className="input_item">
                          <div className="name">
                            Two Factor Code
                            <span className="requried_icon"> *</span>
                          </div>
                          <input
                            type="text"
                            name="factor_code"
                            className="form-control name_value"
                          />
                        </div>
                        <div className="login_button">
                          <Button className="login_btn pad_bottom_40">
                            <i class="fab fa-google"></i> Re-verify with Google
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="profile_headline">Password</div>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form_area">
                        <div className="input_item">
                          <div className="name">
                            Old Password
                            <span className="requried_icon"> *</span>
                          </div>
                          <input
                            type="password"
                            name="old_password"
                            className="form-control name_value"
                          />
                        </div>
                        <div className="input_item">
                          <div className="name">
                            New Password
                            <span className="requried_icon"> *</span>
                          </div>
                          <input
                            type="password"
                            name="new_password"
                            className="form-control name_value"
                          />
                        </div>
                        <div className="input_item">
                          <div className="name">
                            Confirm New Password
                            <span className="requried_icon"> *</span>
                          </div>
                          <input
                            type="password"
                            name="confirm_new_password"
                            className="form-control name_value"
                          />
                        </div>
                        <div className="login_button">
                          <Button className="login_btn pad_bottom_40">
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}

              {activeContent === "vip" && (
                <>
                  <h1 className="text-center"> This is Vip Access</h1>
                </>
              )}
              {activeContent === "transactions" && (
                <>
                  <div className="profile_headline">
                    Transactions
                    <span className="toggle_content">
                      <div class="transaction_mode">
                        <Link
                          hreflang={Translator.getSelectedLanguage().code}
                          to="#"
                          // onClick={() => toggleTab("login")}
                          className="mode_btn"
                        >
                          Deposits
                        </Link>
                        <Link
                          hreflang={Translator.getSelectedLanguage().code}
                          to="#"
                          // onClick={() => toggleTab("Sign up")}
                          className="mode_btn active"
                        >
                          Withdrawals
                        </Link>
                      </div>
                    </span>
                  </div>
                  <div className="clearfix"></div>

                  <div className="transactions">
                    <div className="transaction_row header_row">
                      <div className="single_columns heading">Date</div>
                      <div className="single_columns heading">Amount</div>
                      <div className="single_columns heading">Currency</div>
                      <div className="single_columns heading">Address</div>
                      <div className="single_columns heading">View</div>
                    </div>
                    <div className="transaction_row">
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BTC</div>
                      <div className="single_columns">
                        bc1q68xsuyus5...qh0ur5nxutsargh4y9g
                      </div>
                      <div className="single_columns outlinks">
                        <Link
                          hreflang={Translator.getSelectedLanguage().code}
                          to="#"
                        >
                          View
                          <i class="fal fa-external-link"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="transaction_row">
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BTC</div>
                      <div className="single_columns">
                        bc1q68xsuyus5...qh0ur5nxutsargh4y9g
                      </div>
                      <div className="single_columns outlinks">
                        <Link
                          hreflang={Translator.getSelectedLanguage().code}
                          to="#"
                        >
                          View
                          <i class="fal fa-external-link"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="transaction_row">
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BTC</div>
                      <div className="single_columns">
                        bc1q68xsuyus5...qh0ur5nxutsargh4y9g
                      </div>
                      <div className="single_columns outlinks">
                        <Link
                          hreflang={Translator.getSelectedLanguage().code}
                          to="#"
                        >
                          View
                          <i class="fal fa-external-link"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="transaction_row">
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BTC</div>
                      <div className="single_columns">
                        bc1q68xsuyus5...qh0ur5nxutsargh4y9g
                      </div>
                      <div className="single_columns outlinks">
                        <Link
                          hreflang={Translator.getSelectedLanguage().code}
                          to="#"
                        >
                          View
                          <i class="fal fa-external-link"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="transaction_row">
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BTC</div>
                      <div className="single_columns">
                        bc1q68xsuyus5...qh0ur5nxutsargh4y9g
                      </div>
                      <div className="single_columns outlinks">
                        <Link
                          hreflang={Translator.getSelectedLanguage().code}
                          to="#"
                        >
                          View
                          <i class="fal fa-external-link"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="transaction_row">
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BTC</div>
                      <div className="single_columns">
                        bc1q68xsuyus5...qh0ur5nxutsargh4y9g
                      </div>
                      <div className="single_columns outlinks">
                        <Link
                          hreflang={Translator.getSelectedLanguage().code}
                          to="#"
                        >
                          View
                          <i class="fal fa-external-link"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="transaction_row">
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BTC</div>
                      <div className="single_columns">
                        bc1q68xsuyus5...qh0ur5nxutsargh4y9g
                      </div>
                      <div className="single_columns outlinks">
                        <Link
                          hreflang={Translator.getSelectedLanguage().code}
                          to="#"
                        >
                          View
                          <i class="fal fa-external-link"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="transaction_row">
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BTC</div>
                      <div className="single_columns">
                        bc1q68xsuyus5...qh0ur5nxutsargh4y9g
                      </div>
                      <div className="single_columns outlinks">
                        <Link
                          hreflang={Translator.getSelectedLanguage().code}
                          to="#"
                        >
                          View
                          <i class="fal fa-external-link"></i>
                        </Link>
                      </div>
                    </div>
                    <div className="transaction_row">
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BTC</div>
                      <div className="single_columns">
                        bc1q68xsuyus5...qh0ur5nxutsargh4y9g
                      </div>
                      <div className="single_columns outlinks">
                        <Link
                          hreflang={Translator.getSelectedLanguage().code}
                          to="#"
                        >
                          View
                          <i class="fal fa-external-link"></i>
                        </Link>
                      </div>
                    </div>
                  </div>
                  <div className="pagination_content">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={450}
                      pageRangeDisplayed={7}
                      activeClass="active"
                      itemClassFirst="first"
                      itemClassLast="last"
                      prevPageText={<i class="far fa-angle-left"></i>}
                      nextPageText={<i class="far fa-angle-right"></i>}

                      // onChange={this.handlePageChange.bind(this)}
                    />
                  </div>
                </>
              )}
              {activeContent === "bet history" && (
                <>
                  <div className="profile_headline">
                    Bet History
                    <span className="toggle_content">
                      <div class="transaction_mode">
                        <Link
                          hreflang={Translator.getSelectedLanguage().code}
                          to="#"
                          // onClick={() => toggleTab("login")}
                          className="mode_btn"
                        >
                          Deposits
                        </Link>
                        <Link
                          hreflang={Translator.getSelectedLanguage().code}
                          to="#"
                          // onClick={() => toggleTab("Sign up")}
                          className="mode_btn active"
                        >
                          Withdrawals
                        </Link>
                      </div>
                    </span>
                  </div>
                  <div className="clearfix"></div>

                  <div className="transactions">
                    <div className="transaction_row header_row bet_history">
                      <div className="single_columns heading">Game</div>
                      <div className="single_columns heading">Bet ID</div>
                      <div className="single_columns heading">Date</div>
                      <div className="single_columns heading">Amount</div>
                      <div className="single_columns heading">Currency</div>
                      <div className="single_columns heading"> Multiplier</div>
                    </div>

                    <div className="transaction_row bet_history">
                      <div className="single_columns">Floating Dragon</div>
                      <div className="single_columns">64079469303</div>
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BDT</div>
                      <div className="single_columns">0.00X</div>
                    </div>
                    <div className="transaction_row bet_history">
                      <div className="single_columns">Floating Dragon</div>
                      <div className="single_columns">64079469303</div>
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BDT</div>
                      <div className="single_columns">0.00X</div>
                    </div>
                    <div className="transaction_row bet_history">
                      <div className="single_columns">Floating Dragon</div>
                      <div className="single_columns">64079469303</div>
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BDT</div>
                      <div className="single_columns">0.00X</div>
                    </div>
                    <div className="transaction_row bet_history">
                      <div className="single_columns">Floating Dragon</div>
                      <div className="single_columns">64079469303</div>
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BDT</div>
                      <div className="single_columns">0.00X</div>
                    </div>
                    <div className="transaction_row bet_history">
                      <div className="single_columns">Floating Dragon</div>
                      <div className="single_columns">64079469303</div>
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BDT</div>
                      <div className="single_columns">0.00X</div>
                    </div>
                    <div className="transaction_row bet_history">
                      <div className="single_columns">Floating Dragon</div>
                      <div className="single_columns">64079469303</div>
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BDT</div>
                      <div className="single_columns">0.00X</div>
                    </div>
                    <div className="transaction_row bet_history">
                      <div className="single_columns">Floating Dragon</div>
                      <div className="single_columns">64079469303</div>
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BDT</div>
                      <div className="single_columns">0.00X</div>
                    </div>
                    <div className="transaction_row bet_history">
                      <div className="single_columns">Floating Dragon</div>
                      <div className="single_columns">64079469303</div>
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BDT</div>
                      <div className="single_columns">0.00X</div>
                    </div>
                    <div className="transaction_row bet_history">
                      <div className="single_columns">Floating Dragon</div>
                      <div className="single_columns">64079469303</div>
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BDT</div>
                      <div className="single_columns">0.00X</div>
                    </div>
                    <div className="transaction_row bet_history">
                      <div className="single_columns">Floating Dragon</div>
                      <div className="single_columns">64079469303</div>
                      <div className="single_columns">3:22 PM 11/30/2021</div>
                      <div className="single_columns">4.06000000</div>
                      <div className="single_columns">BDT</div>
                      <div className="single_columns">0.00X</div>
                    </div>
                  </div>
                  <div className="pagination_content">
                    <Pagination
                      activePage={activePage}
                      itemsCountPerPage={10}
                      totalItemsCount={450}
                      pageRangeDisplayed={7}
                      activeClass="active"
                      itemClassFirst="first"
                      itemClassLast="last"
                      prevPageText={<i class="far fa-angle-left"></i>}
                      nextPageText={<i class="far fa-angle-right"></i>}

                      // onChange={this.handlePageChange.bind(this)}
                    />
                  </div>
                </>
              )}
              {activeContent === "verify" && (
                <>
                  <div className="profile_headline">
                    Level 1
                    <span className="verified_badge level_up">Verified</span>
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form_area">
                        <div className="input_item">
                          <div className="name description">
                            In the event your details change, level one
                            verification can be updated at a later date
                          </div>
                        </div>

                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <div className="input_item">
                              <div className="name">
                                First Name
                                <span className="requried_icon"> *</span>
                              </div>
                              <input
                                type="text"
                                name="first_name"
                                className="form-control name_value"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="input_item">
                              <div className="name">
                                Last Name
                                <span className="requried_icon"> *</span>
                              </div>
                              <input
                                type="text"
                                name="last_name"
                                className="form-control name_value"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="input_item">
                              <div className="name">
                                Date of Birth
                                <span className="requried_icon"> *</span>
                              </div>
                              <input
                                type="date"
                                name="dob"
                                className="form-control name_value"
                              />
                            </div>
                          </div>
                          <div className="col-12">
                            <div className="input_item">
                              <div className="name">
                                Address
                                <span className="requried_icon"> *</span>
                              </div>
                              <input
                                type="text"
                                name="address"
                                className="form-control name_value"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="input_item">
                              <div className="name">
                                City
                                <span className="requried_icon"> *</span>
                              </div>
                              <input
                                type="text"
                                name="city"
                                className="form-control name_value"
                              />
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="input_item">
                              <div className="name">
                                Postal Code
                                <span className="requried_icon"> *</span>
                              </div>
                              <input
                                type="text"
                                name="postal_code"
                                className="form-control name_value"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="login_button">
                          <Button className="login_btn pad_bottom_40">
                            Save Changes
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile_headline">Level 2</div>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form_area">
                        <div className="input_item">
                          <div className="name">
                            Please upload your Proof of Identity. All documents
                            must be lying on a flat surface with all 4 corners
                            inside the frame, and all information should be
                            clear and identifiable. While photographs of ID
                            cards and Passport are acceptable, scanned documents
                            are not.
                          </div>
                          <div className="name description">
                            Following file types are accepted: png, jpg, .pdf
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <div className="input_item">
                              <div className="name">
                                Proof of Identity (Front)
                                <span className="requried_icon"> *</span>
                              </div>
                              <div className="form-control name_value">
                                {identityFrontFileName}
                              </div>
                              <input
                                onChange={pickIdentityFront}
                                id="identity_front"
                                type="file"
                                hidden
                                name="identity_front"
                                className="form-control name_value"
                              />
                              <label className="d-block" for="identity_front">
                                {!!identityFrontFileName || (
                                  <i className="fal fa-file-upload in_input_icon"></i>
                                )}
                                {!!identityFrontFileName && (
                                  <i class="fad fa-check-square in_input_icon upload_success"></i>
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="input_item">
                              <div className="name">
                                Proof of Identity (Back)
                                <span className="requried_icon"> *</span>
                              </div>
                              <div className="form-control name_value">
                                {identityBackFileName}
                              </div>
                              <input
                                onChange={pickIdentityBack}
                                id="identity_back"
                                type="file"
                                hidden
                                name="identity_back"
                                className="form-control name_value"
                              />
                              <label className="d-block" for="identity_back">
                                {!!identityBackFileName || (
                                  <i className="fal fa-file-upload in_input_icon"></i>
                                )}
                                {!!identityBackFileName && (
                                  <i class="fad fa-check-square in_input_icon upload_success"></i>
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="login_button">
                          <Button className="login_btn pad_bottom_40">
                            Upload
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="profile_headline">
                    Level 3
                    <span className="alert_text">
                      Please complete level two verification first.
                    </span>
                  </div>
                  <div className="row">
                    <div className="col-md-5">
                      <div className="form_area">
                        <div className="input_item">
                          <div className="name">
                            Please upload supporting documentation for your
                            Source of Funds and Proof of Address. Documents
                            lying a on a flat surface must show all 4 corners
                            and all information should be clear and
                            identifiable.
                          </div>
                          <div className="name description">
                            Following file types are accepted: png, jpg, .pdf
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12 col-sm-6">
                            <div className="input_item">
                              <div className="name">
                                Source of Funds
                                <span className="requried_icon"> *</span>
                              </div>
                              <div className="form-control name_value">
                                {fundFileName}
                              </div>
                              <input
                                onChange={pickFundFile}
                                id="fund_file"
                                type="file"
                                hidden
                                name="fund_file"
                                className="form-control name_value"
                              />
                              <label className="d-block" for="fund_file">
                                {!!fundFileName || (
                                  <i className="fal fa-file-upload in_input_icon"></i>
                                )}
                                {!!fundFileName && (
                                  <i class="fad fa-check-square in_input_icon upload_success"></i>
                                )}
                              </label>
                            </div>
                          </div>
                          <div className="col-12 col-sm-6">
                            <div className="input_item">
                              <div className="name">
                                Proof of Address
                                <span className="requried_icon"> *</span>
                              </div>
                              <div className="form-control name_value">
                                {addressFileName}
                              </div>
                              <input
                                onChange={pickAddressFile}
                                id="address_file"
                                type="file"
                                hidden
                                name="address_file"
                                className="form-control name_value"
                              />
                              <label className="d-block" for="address_file">
                                {!!addressFileName || (
                                  <i className="fal fa-file-upload in_input_icon"></i>
                                )}
                                {!!addressFileName && (
                                  <i class="fad fa-check-square in_input_icon upload_success"></i>
                                )}
                              </label>
                            </div>
                          </div>
                        </div>
                        <div className="login_button">
                          <Button className="login_btn pad_bottom_40">
                            Upload
                          </Button>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
