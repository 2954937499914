import React, { useState, useEffect } from "react";
import { Redirect, Route, Link } from "react-router-dom";

const TransactionDataView = (props) => {
  const [transactions, setTransactions] = useState({});
  useEffect(() => {
    setTransactions(props.dataSet);
  }, [props]);

  return (
    <div className="transactions">
      {props.transactionType == "Deposits" && (
        <>
          <div className="transaction_row header_row">
            <div className="single_columns heading">Date</div>
            <div className="single_columns heading">Amount</div>
            <div className="single_columns heading">Package</div>
            <div className="single_columns heading">Payment Method</div>
          </div>
          {transactions.length > 0 &&
            transactions.map((value, index) => (
              <>
                <div className="transaction_row">
                  <div className="single_columns">{value.action_date}</div>
                  <div className="single_columns">{value.amount}</div>
                  <div className="single_columns">
                    SC:{value?.sweepcoinPurchase?.sweep_coins}
                    <br />
                    GC: {value?.sweepcoinPurchase?.gold_coins}
                  </div>
                  <div className="single_columns">{value.method}</div>
                  {/* <div className="single_columns outlinks">
                    <Link hreflang={Translator.getSelectedLanguage().code} to="#">
                      View
                      <i class="fal fa-external-link"></i>
                    </Link>
                  </div> */}
                </div>
              </>
            ))}
        </>
      )}
      {props.transactionType == "Withdrawals" && (
        <>
          <div className="transaction_row header_row">
            <div className="single_columns heading">Date</div>
            <div className="single_columns heading">SC amount redeemed</div>
            <div className="single_columns heading">Redeem Method </div>
          </div>
          {transactions.length > 0 &&
            transactions.map((value, index) => (
              <>
                <div className="transaction_row">
                  <div className="single_columns">{value.action_date}</div>
                  <div className="single_columns">{value.amount}</div>
                  <div className="single_columns">{value.method}</div>
                </div>
              </>
            ))}
        </>
      )}
    </div>
  );
};

export default TransactionDataView;
