import React, { useState } from "react";
import { Redirect, Route, Link, useParams, useHistory } from "react-router-dom";
import Translator, { __ } from "services/Translator";
import ProviderFilter from "./ProviderFilter";
import SearchBox from "../SearchBox";
import Slider from "react-slick";

import { Dropdown } from "react-bootstrap";
import ProviderFilterDropdown from "elements/GameElements/ProviderFilterDropdown";

import { Scrollbars } from "react-custom-scrollbars";
export default function GamesFilter(props) {
  const history = useHistory();
  const params = useParams();
  const gamesCategory = params.category ? params.category : "all";

  const settings = {
    dots: false,
    infinite: false,
    arrows: false,
    speed: 500,
    slidesToShow: 8,
    slidesToScroll: 8,
    variableWidth: true,
    swipeToSlide: true,
    responsive: [
      {
        breakpoint: 2000,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 6,
        },
      },
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,

        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
    ],
  };

  return (
    <>
      {!props.isMobileDevice && (
        <SearchBox className="search_on_gamefilter" {...props} />
      )}
      <ProviderFilterDropdown {...props} />
    </>
  );
}
