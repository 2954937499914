import React, { useState, useEffect } from "react";
import { Redirect, Route, Link, useHistory } from "react-router-dom";
import auth from "../services/auth";
import ls from "local-storage";
import AppContext from "../contexts/AppContext";
import api from "../services/api";
import FavoriteGame from "elements/FavoriteGame";
import Swal from "sweetalert2";
import Translator, { __ } from "services/Translator";

const CategoryGames = (props) => {
  // const menuOpen = props.menuOpen;
  const history = useHistory();
  const [seconds, setSeconds] = useState(4083958);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((seconds) => seconds + 1);
    }, 60);
    return () => clearInterval(interval);
  }, []);

  // const [categoryName, setCategoryName] = useState(props.categoryName);
  // const [showSidebar, setShowSidebar] = useState(false);

  const openSidebar = () => {
    setShowSidebar(true);
  };
  const closeSidebar = () => {
    setShowSidebar(false);
  };

  // alert(props.categoryName);
  const [categoryName, setCategoryName] = useState("");
  const [isAuthenticated, setIsAuthenticated] = useState(auth.isAuthenticated);
  const [gameListsData, setGameListsData] = useState([]);
  const [popularSlots, setPopularSlots] = useState([]);
  const [tableGames, setTableGames] = useState([]);
  const [gameType, setGameType] = useState(null);
  const [page, setPage] = useState(1);
  const [perPage, setPerPage] = useState(props.perPage);
  const [title, setTitle] = useState(props.title);

  const getGames = async () => {
    if (page == 1) {
      setGameListsData([]);
      // console.log('setGameListsDatasetGameListsData', gameListsData)
    }
    var response = await api.post("/games/by-category", {
      per_page: perPage,
      page: page,
      game_types: [gameType],
      specific_games: props.specificGames,
      game_sorting: props.sortStr,
    });

    if (response.status === 200 && response.data) {
      // alert(gameListsData.length)
      if (gameType == "popular") {
        // const tempGameList = popularSlots;
        // var mergedGameList = tempGameList.concat(
        //   response.data.games.popular.gamelist
        // );
        // setPopularSlots(mergedGameList);

        const tempGameList = page == 1 ? [] : gameListsData;
        var mergedGameList = tempGameList.concat(
          response.data.games.popular.gamelist
        );
        setGameListsData(mergedGameList);
      } else if (gameType == "table_games") {
        // const tempGameList = tableGames;
        // var mergedGameList = tempGameList.concat(
        //   response.data.games.table_games.gamelist
        // );
        // setTableGames(mergedGameList);

        const tempGameList = page == 1 ? [] : gameListsData;
        var mergedGameList = tempGameList.concat(
          response.data.games.table_games.gamelist
        );
        setGameListsData(mergedGameList);
      } else if (gameType == "video_slots") {
        // const tempGameList = videoSlots;
        // const tempGameList = [];
        const tempGameList = page == 1 ? [] : gameListsData;
        var mergedGameList = tempGameList.concat(
          response.data.games.video_slots.gamelist
        );

        // console.log("mergedGameList", mergedGameList);
        setGameListsData(mergedGameList);
        // setGameListsData(response.data.games.video_slots.gamelist);
      } else if (gameType == "new") {
        // const tempGameList = videoSlots;
        // const tempGameList = [];
        const tempGameList = page == 1 ? [] : gameListsData;
        var mergedGameList = tempGameList.concat(
          response.data.games.new.gamelist
        );

        // console.log("mergedGameList", mergedGameList);
        setGameListsData(mergedGameList);
        // setGameListsData(response.data.games.video_slots.gamelist);
      }
    }
  };

  useEffect(() => {
    if (props.categoryName == "Popular Slots") {
      setGameType("popular");
    } else if (props.categoryName == "Table Games") {
      setGameType("table_games");
    } else if (props.categoryName == "Slots") {
      setGameType("video_slots");
    } else if (props.categoryName == "Jackpots") {
      setGameType("jackpots");
    } else if (props.categoryName == "All Games") {
      setGameType("popular");
    } else if (props.categoryName == "New Games") {
      setGameType("new");
    } else if (props.categoryName == "Search") {
      setGameType("search");
    }
    // console.log('category', props.categoryName)
    setGameListsData([]);
    setPage(1);
  }, [props.categoryName]);

  // useEffect( () => {
  //   console.log('categoryName', props.categoryName)
  //   setCategoryName(props.categoryName);
  // }, [props.categoryName]);

  useEffect(() => {
    setTitle(props.title);
  }, [props.title]);

  useEffect(() => {
    if (gameType === "search") {
      searchGames(page, null);
    } else if (gameType && gameType != "search") {
      getGames(page);
    }
  }, [gameType, page, props.sortStr]);

  const [showSidebar, setShowSidebar] = useState(false);

  const loadMoreGamesAction = (prm) => {
    setPage(page + 1);
    setPerPage(20);
  };

  const [isSearchResultsShowing, setIsSearchResultsShowing] = useState(false);
  const searchGames = async (page) => {
    if (!props.searchStr) {
      history.push("/");
    }
    var serchParams = {
      search_query: props.searchStr,
      page: page,
      per_page: perPage,
      providers: props.selectedProviders,
      game_sorting: props.sortStr,
    };

    var response = await api.post("/games/search", serchParams);
    if (response.status === 200) {
      setIsSearchResultsShowing(true);
      setGameListsData(response.data.games.search_results.gamelist);
    }
  };

  const hideNoImageFoundGames = () => {
    // setVisible(false);
  };

  return (
    <>
      <section className="game_content_area">
        {/* <div className="common_headline">{title}</div> */}
        <div className="common_headline">{props.categoryName}</div>

        {categoryName !== "Jackpots" && (
          <div className="games_list">
            {gameListsData.length > 0 &&
              gameListsData.map((game, index) => (
                <React.Fragment key={index}>
                  <div className="single_game">
                    <div className="game_thumb">
                      <img
                        onError={hideNoImageFoundGames}
                        src={game.game_snapshot_url}
                      />
                      <div className="identyText-overview">
                        {/* <h6 className="game_name">{game.game_name}</h6> */}

                        <div class="game_action">
                          {!isAuthenticated && (
                            <Link
                              hreflang={Translator.getSelectedLanguage().code}
                              class="game_play_icon"
                              onClick={() => {
                                Swal.fire({
                                  className: "custom_swal",
                                  title: "Warning",
                                  text: "You need to be logged in to play games.",
                                  icon: "warning",
                                  background: "#2e2e2e", // Dark background color
                                  color: "#fff", // White text color
                                  customClass: {
                                    popup: "dark-theme-popup",
                                    title: "dark-theme-title",
                                    content: "dark-theme-content",
                                  },
                                }).then((result) => {
                                  history.push("/login");
                                });
                              }}
                            >
                              <i class="fas fa-play"></i>
                            </Link>
                          )}
                          {isAuthenticated && (
                            <Link
                              hreflang={Translator.getSelectedLanguage().code}
                              class="game_play_icon"
                              to={"/play/" + game.slug + "/real"}
                            >
                              <i class="fas fa-play"></i>
                            </Link>
                          )}
                        </div>
                        <h3 class="game_provider_name">
                          {game?.provider?.name}
                        </h3>
                        {/* <Link hreflang={Translator.getSelectedLanguage().code}
                          className=" common_button btnType-three"
                          to={"/play/" + game.slug + "/real"}
                        >
                          <span>Play now</span>
                        </Link> */}

                        {/* <Link hreflang={Translator.getSelectedLanguage().code} className="common_button btnType-three" to="#">
                          <span>Play now</span>
                        </Link> */}
                      </div>
                      <FavoriteGame
                        gameid={game.id}
                        is_favorite_game={game.is_favorite_game}
                        {...props}
                      />
                    </div>
                    {/* <h4 className="game_title">{game.game_name}</h4> */}
                    {/* <div className="mask_layer"></div> */}

                    {/* <div className="identyText-overview">
                      <p>{game.game_name}</p>
                    </div> */}
                  </div>
                </React.Fragment>
              ))}
          </div>
        )}

        {categoryName === "Jackpots" && (
          <div className="games_list">
            <div className="single_game">
              <Link hreflang={Translator.getSelectedLanguage().code} to="#">
                <img src={require("assets/images/games/Rounders.png")} />
                <div className="mask_layer"></div>
              </Link>
              <div className="sc_badge no_bg golden">
                <strong>Grand GC</strong>
                {seconds}
              </div>
            </div>
            <div className="single_game group_game">
              <div className="single_game">
                <Link hreflang={Translator.getSelectedLanguage().code} to="#">
                  <img src={require("assets/images/games/Ra To Riches.png")} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="single_game">
                <Link hreflang={Translator.getSelectedLanguage().code} to="#">
                  <img src={require("assets/images/games/Sugar Kisses.png")} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="sc_badge">
                <strong>Grand GC</strong> {seconds}
              </div>
            </div>
            <div className="single_game group_game">
              <div className="single_game">
                <Link hreflang={Translator.getSelectedLanguage().code} to="#">
                  <img src={require("assets/images/games/Wicked Reels.png")} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="single_game">
                <Link hreflang={Translator.getSelectedLanguage().code} to="#">
                  <img src={require("assets/images/games/Wizardry.png")} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="sc_badge">
                <strong>Grand GC</strong> {seconds}
              </div>
            </div>

            <div className="single_game">
              <Link hreflang={Translator.getSelectedLanguage().code} to="#">
                <img src={require("assets/images/games/Aztec Sun Stone.png")} />
                <div className="mask_layer"></div>
              </Link>
              <div className="sc_badge no_bg golden">
                <strong>Grand GC</strong> {seconds}
              </div>
            </div>
            <div className="single_game group_game">
              <div className="single_game">
                <Link hreflang={Translator.getSelectedLanguage().code} to="#">
                  <img
                    src={require("assets/images/games/Big Fat Turkey.png")}
                  />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="single_game">
                <Link hreflang={Translator.getSelectedLanguage().code} to="#">
                  <img
                    src={require("assets/images/games/Fruitti Friends.png")}
                  />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="sc_badge">
                <strong>Grand GC</strong> {seconds}
              </div>
            </div>
            <div className="single_game group_game">
              <div className="single_game">
                <Link hreflang={Translator.getSelectedLanguage().code} to="#">
                  <img
                    src={require("assets/images/games/Halloween Toons.png")}
                  />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="single_game">
                <Link hreflang={Translator.getSelectedLanguage().code} to="#">
                  <img src={require("assets/images/games/Puppy Party.png")} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="sc_badge">
                <strong>Grand GC</strong> {seconds}
              </div>
            </div>
            <div className="single_game group_game">
              <div className="single_game">
                <Link hreflang={Translator.getSelectedLanguage().code} to="#">
                  <img src={require("assets/images/games/Rounders.png")} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="single_game">
                <Link hreflang={Translator.getSelectedLanguage().code} to="#">
                  <img src={require("assets/images/games/Ra To Riches.png")} />
                  <div className="mask_layer"></div>
                </Link>
              </div>
              <div className="sc_badge">
                <strong>Grand GC</strong> {seconds}
              </div>
            </div>
          </div>
        )}

        <Link
          hreflang={Translator.getSelectedLanguage().code}
          onClick={loadMoreGamesAction}
          className="loadMoreBtn"
        >
          Load More
        </Link>
      </section>
    </>
  );
};

export default CategoryGames;
