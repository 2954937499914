import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";

import Translator, { __ } from "services/Translator";
import api from "services/api";
import { Modal } from "react-bootstrap";

export default function AffiliatesTermsConditions(props) {
  const [showAffiliateTermsModal, setShowAffiliateTermsModal] = useState(true);
  const [pageContent, setPageContent] = useState("");
  const getContentPage = async () => {
    var response = await api.get("/get-content-page", {
      page_uid: "affiliates_terms_conditions",
    });

    if (response.status == 200) {
      setPageContent(response.data);
    }
  };

  useEffect( () => {
    getContentPage();
  }, []);
  const renderHTML = (rawHTML: string) =>
    React.createElement("div", {
      dangerouslySetInnerHTML: { __html: rawHTML },
    });
  return (
    <Modal
      show={props.showAffiliateTermsModal}
      onHide={() => props.setShowAffiliateTermsModal(false)}
      dialogClassName={
        "greyStyleModal affiliateTermsModal " +
        (props.isMobileDevice ? "modal-100w " : "modal-70w ")
      }
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          {__("Affiliates Terms & Conditions")}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="affiliate_terms_area">
          <p className="paragraph desc">
            {pageContent && renderHTML(pageContent.content_data.content)}
          </p>
        </div>
      </Modal.Body>
    </Modal>
  );
}
